import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  set_category_id: number;
  set_name: string;
  set_box_count: string;
}

export function PostSetProductBaseInfo(
  token: string,
  data: any,
  params: Props
) {
  //data: any

  return axios.post(`${apiUrl}/api/admin/set-product/base-info`, data, {
    params: {
      set_category_id: params.set_category_id,
      set_name: params.set_name,
      set_box_count: params.set_box_count,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
