import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { AdminHeader } from "../../components/common/AdminHeader";

const PageWrap = styled.div``;

const ContentWrap = styled.div`
  background-color: #efefef;
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 250px;
    height: 300px;
    border: 2px solid #5bb331;
    background-color: #fff;
    color: #5bb331;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      background-color: #efefef;
    }

    & + a {
      margin-left: 60px;
    }
  }
`;

export function SelectUploadProduct() {
  return (
    <PageWrap>
      <AdminHeader />
      <ContentWrap>
        <Link to="/single-product">
          <img src="" />
          <p>단일 상품 업로드</p>
        </Link>
        <Link to="/multiple-product">
          <img src="" />
          <p>세트 상품 업로드</p>
        </Link>
      </ContentWrap>
    </PageWrap>
  );
}
