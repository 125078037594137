import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  page: number;
  rows: number;
  order_by: string;
  order_type: string;
  category_id?: any;
  prd_name?: any;
}

export function GetAllSingleProduct(token: string, params: Props) {
  return axios.get(`${apiUrl}/api/admin/product`, {
    params: {
      page: params.page,
      rows: params.rows,
      order_by: params.order_by,
      order_type: params.order_type,
      category_id: params.category_id,
      prd_name: params.prd_name,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
