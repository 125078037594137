import { createContext, useState } from "react";

// Context 타입 정의
interface UserContextType {
  userId: string;
  setUserId: (id: string) => void;
}

// Context 생성
const UserContext = createContext<UserContextType>({
  userId: "",
  setUserId: () => {},
});

// Provider 컴포넌트 생성
const UserIdProvider = ({ children }: { children: React.ReactNode }) => {
  const [userId, setUserId] = useState<string>("");
  return (
    <UserContext.Provider value={{ userId, setUserId }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserIdProvider };
