// import axios from "axios";
// const apiUrl = process.env.REACT_APP_API_URL;

// interface Props {
//   order_by: string;
//   order_type: string;
//   set_order_by?: any;
//   set_order_type?: any;
// }

// export function GetAllCategories(token: string, params: Props) {
//   return axios.get(`${apiUrl}/api/client/all-categories`, {
//     params: {
//       order_by: params.order_by,
//       order_type: params.order_type,
//       set_order_by: params.set_order_by,
//       set_order_type: params.set_order_type,
//     },
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//   });
// }

// sessionStorage 저장 성공
import axios from "axios";
import LZString from "lz-string";

const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  order_by: string;
  order_type: string;
  set_order_by?: any;
  set_order_type?: any;
}

export function GetAllCategories(token: string, params: Props) {
  const etagKey = "category_etag"; // API별 E-Tag Key
  const etag = sessionStorage.getItem(etagKey); // 로컬 스토리지에서 해당 API의 E-Tag 가져오기

  return axios
    .get(`${apiUrl}/api/client/all-categories`, {
      params: {
        order_by: params.order_by,
        order_type: params.order_type,
        set_order_by: params.set_order_by,
        set_order_type: params.set_order_type,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "If-None-Match": etag || "", // E-Tag가 있으면 If-None-Match에 추가
      },
      validateStatus: (status) => {
        // 304 상태 코드는 에러가 아니므로, 정상 응답으로 처리
        return status >= 200 && status < 400; // 200-399 범위에서 정상 응답
      },
    })
    .then((response) => {
      // 304 상태 코드 확인
      if (response.status === 304) {
        console.log("No changes in categories. Using cached data.");

        // sessionStorage에서 압축된 데이터 가져오기
        const compressedCachedData = sessionStorage.getItem(
          `cached_${etagKey}_response`
        );
        if (compressedCachedData) {
          // decompress 처리리
          const decompressedData = LZString.decompress(compressedCachedData);
          return { data: JSON.parse(decompressedData) }; // 캐시된 데이터를 반환
        } else {
          console.error("No cached data found.");
          return { data: null }; // 캐시가 없을 경우, 새 데이터를 받아야 할 수 있음
        }
      }

      // E-Tag가 응답 헤더에 있으면 저장
      const newEtag = response.headers["e-tag"];
      if (newEtag) {
        sessionStorage.setItem(etagKey, newEtag); // API별 최신 E-Tag 저장

        // 데이터를 압축하여 sessionStorage에 저장
        const originalData = JSON.stringify(response.data);
        // console.log("Original Data Size:", originalData.length); // 압축 전 데이터 크기

        const compressedData = LZString.compress(JSON.stringify(response.data));
        // console.log("Compressed Data Size:", compressedData.length); // 압축 후 데이터 크기

        sessionStorage.setItem(`cached_${etagKey}_response`, compressedData); // API별 캐시 데이터 저장
      }

      return response;
    })
    .catch((error) => {
      console.error("Error fetching categories:", error);
      throw error;
    });
}
