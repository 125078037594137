import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { GetCategory } from "../../api/category/single/GetCategory";
import { AdminHeader } from "../../components/common/AdminHeader";
import { TextInput } from "../../components/common/TextInput";
import Cookies from "universal-cookie";
import { PostSetProductBaseInfo } from "../../api/product/set/PostSetProductBaseInfo";
import { GetSetCategory } from "../../api/category/set/GetSetCategory";
import { PostSetProductImg } from "../../api/product/set/PostSetProductImg";
import { GetSetProductBaseInfo } from "../../api/product/set/GetSetProductBaseInfo";
import queryString from "query-string";
import { GetSetProductThumbnail } from "../../api/product/set/GetSetProductThumbnail";
import { PutSetProductBaseInfo } from "../../api/product/set/PutSetProductBaseInfo";
import { GetSetProductDetailImageInfo } from "../../api/product/set/GetSetProductDetailImageInfo";
import { GetSetProductImgList } from "../../api/product/set/GetSetProductImgList";
import { PutSetProductImg } from "../../api/product/set/PutSetProductImg";
import { ProductDetailPreview } from "../../components/ProductDetailPreview";
import { toast } from "react-toastify";
import { CheckModal } from "../../components/modal/CheckModal";
import { RiInformation2Line } from "@remixicon/react";

const PageWrap = styled.div``;

const ContentWrap = styled.div`
  background-color: #efefef;
  width: 100%;
  /* height: calc(100vh - 80px); */
  height: fit-content;
  min-height: 450px;
  overflow: hidden;
  padding-bottom: 100px;
`;

const Title = styled.h4`
  width: 80%;
  margin: 50px auto 0;
  display: flex;
  align-items: center;

  p {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;

    ul {
      position: absolute;
      left: 40px;
      top: 0;
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid #cbcbcb;
      line-height: 28px;
      z-index: 99;
      width: 500px;
      text-align: left;

      li {
        /* list-style: circle; */
        font-weight: 100;
        text-indent: 20px;

        &:first-child {
          list-style: none;
          font-weight: 400;
          padding-bottom: 20px;
        }
      }
    }
  }
`;

const Form = styled.form`
  width: 80%;
  margin: 20px auto 0;
`;

const FormInfoWrap = styled.div`
  width: 100%;
  border-top: 2px solid #444;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
`;

const ProductInfoSection = styled.div`
  padding: 10px 0 30px;
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  justify-content: space-between;
  div {
    div {
      & + div {
        margin-left: 20px;
      }
    }
  }

  button {
    background-color: #fff;
    border: 2px solid #5bb331;
    box-sizing: border-box;
    padding: 5px 10px;
    height: auto;
    border-radius: 5px;
    color: #5bb331;
    cursor: pointer;
  }
`;

const AddProductListSection = styled.div`
  background-color: #dddddd;
  height: 300px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const Product = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-left: 3px solid #5bb331;

  & + div {
    margin-top: 10px;
  }

  & > div {
    width: 100%;
  }

  p {
    width: 170px;
    text-indent: 10px;
  }

  div {
    & + div {
      margin-left: 30px;
    }
  }

  button {
    font-size: 30px;
    font-weight: 400;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;

const ThumbNailSection = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid #cbcbcb;
  p {
    padding-bottom: 20px;
  }
`;
const DetailImgSection = styled.div`
  padding: 30px 0;
  p {
    padding-bottom: 20px;
  }
  div {
    display: flex;
    justify-content: flex-start;
    div {
      & + div {
        margin-left: 20px;
      }
    }
  }
`;

const FileUploadBtn = styled.div<{ imageFiles?: boolean }>`
  width: 100px;
  height: 100px;
  line-height: 100px;
  background-color: #e5e5e5;
  position: relative;
  cursor: pointer;
  /* overflow: hidden; */
  border: 1px solid #cbcbcb;

  &:before {
    content: "+";
    display: ${(props) => (props.imageFiles == false ? "block " : "none")};
    position: absolute;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 50px;
    color: #a3a3a3;
    line-height: 110px;
  }
  input {
    display: block;
    width: 100%;
    height: 100%;
    /* position: absolute;
      z-index: -1; */
    opacity: 0;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    top: -10px;
    right: -10px;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    background-color: #b0b0b0;
  }
`;

const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;

  button {
    width: 200px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #444;
    color: #444;
    background-color: #fff;
    cursor: pointer;
    & + button {
      margin-left: 20px;
    }
  }
`;

export function MultiProduct() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");
  const queryParams = queryString.parse(window.location.search);

  const prdId =
    typeof queryParams.prd_id === "string" ? queryParams.prd_id : "";
  const prdName =
    typeof queryParams.set_name === "string" ? queryParams.set_name : "";

  const [singlePrdList, setSinglePrdList] = useState<
    [{ set_category_id: number; set_category_name: string }]
  >([{ set_category_id: 0, set_category_name: "" }]);

  const { control, handleSubmit, getValues, watch, register, reset } =
    useForm();

  const {
    field: set_product_name,
    fieldState: { invalid: set_product_name_Field },
  } = useController({
    name: "set_product_name",
    control,
    defaultValue: "",
  });

  const {
    field: single_product,
    fieldState: { invalid: single_product_Field },
  } = useController({
    name: "single_product",
    control,
    defaultValue: (singlePrdList && singlePrdList[0]?.set_category_name) || "",
  });

  const [product, setProduct] = useState<any[]>([]);
  const [productIdNumber, setProductIdNumber] = useState<number>(0);
  const [imageFiles, setImageFiles] = useState<string | null>(null);
  const [imageFiles01, setImageFiles01] = useState<string | null>(null);
  const [imageFiles02, setImageFiles02] = useState<string | null>(null);
  const [imageFiles03, setImageFiles03] = useState<string | null>(null);
  const [imageFiles04, setImageFiles04] = useState<string | null>(null);
  const [imageFiles05, setImageFiles05] = useState<string | null>(null);

  //upload img
  const [uploadImageFiles, setUploadImageFiles] = useState<File | null>(null);
  const [uploadImageFiles01, setUploadImageFiles01] = useState<File | null>(
    null
  );
  const [uploadImageFiles02, setUploadImageFiles02] = useState<File | null>(
    null
  );
  const [uploadImageFiles03, setUploadImageFiles03] = useState<File | null>(
    null
  );
  const [uploadImageFiles04, setUploadImageFiles04] = useState<File | null>(
    null
  );
  const [uploadImageFiles05, setUploadImageFiles05] = useState<File | null>(
    null
  );

  //데이터 있을 때
  const [setInfodata, setSetInfoData] = useState<{
    set_category_id: number;
    set_category_name: string;
    set_prd_count: number;
  }>({ set_category_id: 0, set_category_name: "", set_prd_count: 0 });
  const [productData, setProductData] = useState<
    {
      set_prd_id: number;
      set_category_id: number;
      set_name: string;
      set_box_count: string;
      set_prd_name: string;
      set_prd_standard: string;
      set_prd_quantity: string;
      set_created_at: Date;
    }[]
  >([
    {
      set_prd_id: 0,
      set_category_id: 0,
      set_name: "",
      set_box_count: "",
      set_prd_name: "",
      set_prd_standard: "",
      set_prd_quantity: "",
      set_created_at: new Date(),
    },
  ]);
  const [originalCategoryId, setOriginalCategoryId] = useState<number>(0);
  const [originalSetName, setOriginalSetName] = useState<string>("");
  const [thumbnail, setThumbnail] = useState<any>(); //화면용 썸네일
  const [thumbnailData, setThumbnailData] = useState<any>(); //데이터 전송용 썸네일
  const [detailImgPath, setDetailImgPath] = useState<string[]>([]); //이미지 경로 리스트
  const [detailImg, setDetailImg] = useState<any[]>([]);
  const [detailImgData, setDetailImgData] = useState<any[] | any>([]);

  //미리보기 데이터
  const [previewData, setPreviewData] = useState<any>(null);

  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

  // useEffect(() => {}, [productIdNumber]);

  //뒤로가기
  const [historyCount, setHistoryCount] = useState<number>(0);
  const [isCheckModal, setIsCheckModal] = useState<boolean>(false);

  useEffect(() => {
    getSetCategory();
    if (prdId) {
      getSetProductBaseInfo();
    }

    const handlePopState = (event: any) => {
      console.log("브라우저의 뒤로가기 버튼이 클릭되었습니다!", event.state);
      setHistoryCount((prevCount) => prevCount + 1); // 클릭할 때마다 카운트 증가
      setIsCheckModal(true);
    };

    // popstate 이벤트 리스너 등록
    window.addEventListener("popstate", handlePopState);

    // 초기 상태 추가 (예: 페이지가 로드될 때)
    window.history.pushState({ page: 1 }, "title 1", window.location.href);

    return () => {
      // 컴포넌트 언마운트 시 리스너 제거
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    // 두 번째 클릭 시 페이지 이동
    if (historyCount === 2) {
      // 예: '/previous-page'로 이동
      window.location.href = "/manage-set-product"; // 원하는 URL로 변경
    } else {
      window.history.pushState({ page: 1 }, "title 1", window.location.href);
    }
  }, [historyCount]);

  /**데이터 받아와서 필드에 적용 */
  useEffect(() => {
    const result = singlePrdList.filter(
      (item) => item.set_category_id === setInfodata.set_category_id
    );
    single_product.onChange({
      target: { value: result[0] && result[0]?.set_category_name },
    });
    // product_name.onChange({ target: { value: data.prd_name } });
    // product_size.onChange({ target: { value: data.prd_standard } });
    // product_count.onChange({ target: { value: data.prd_quantity } });
  }, [singlePrdList, setInfodata]);

  /**productData[0].set_name 이 있을 시, 썸네일 이미지 호출 */
  useEffect(() => {
    if (productData[0].set_name !== "") {
      // getSetProductThumbnail();
      getSetProductDetailImageInfo();
    }
  }, [productData[0].set_name]);

  /**imagePath 있을 때, 이미지 api 호출 */
  useEffect(() => {
    if (detailImgPath.length > 0) {
      getSetProductImgList();
    }
  }, [detailImgPath]);

  const params = {
    page: 1, //currentPage
    rows: 5,
    order_by: "category_id",
    order_type: "asc",
  };

  /**카테고리 리스트 조회 */
  const getSetCategory = () => {
    GetSetCategory(current_cookies, params)
      .then((response) => {
        setSinglePrdList(response.data.data.set_categories_info);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  /**상품 정보 가져오기 api*/
  const getSetProductBaseInfo = () => {
    GetSetProductBaseInfo(current_cookies, parseInt(prdId), prdName)
      .then((response) => {
        setSetInfoData(response.data.data.set_category_info);
        setOriginalCategoryId(
          response.data.data.set_category_info.set_category_id
        );
        setOriginalSetName(
          response.data.data.set_products_info.set_products[0].set_name
        );

        const imgType = getImageType(
          response.data.data.set_category_info.set_prd_thumbnail_img
        );

        setThumbnail(
          `data:${imgType};base64,${response.data.data.set_category_info.set_prd_thumbnail_img}`
        );

        const binaryData = base64ToBinary(
          `data:${imgType};base64,${response.data.data.set_category_info.set_prd_thumbnail_img}`
        );

        setThumbnailData(binaryData);

        const productDataList =
          response.data.data.set_products_info.set_products;

        setProductData(productDataList);

        const filteredList = productDataList.slice(1);

        setProduct(filteredList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      })
      .finally(() => {
        // getSingleProductImgPath();
      });
  };

  /**상품 썸네일 가져오기 api*/
  const getSetProductThumbnail = () => {
    GetSetProductThumbnail(
      current_cookies,
      setInfodata.set_category_id,
      productData[0].set_name
    )
      .then((response) => {
        console.log("response : ", response);

        const imageBlob = new Blob([response.data], {
          type: response.data.type,
        });
        const imageUrl = URL.createObjectURL(imageBlob); //화면 적용용

        //Blob을 File 객체로 변환
        const file = new File([imageBlob], "image.jpg", {
          type: response.data.type,
        });

        // setThumbnail(imageUrl);
        setThumbnailData(file);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  /**base64 => binary 로 변환 */

  const base64ToBinary = (base64Data: string) => {
    // Base64 문자열에서 'data:image/png;base64,' 부분을 제거
    const base64WithoutPrefix = base64Data.split(",")[1];

    // Base64 문자열을 바이너리 데이터로 변환
    const byteCharacters = atob(base64WithoutPrefix);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Blob 객체 생성
    const blob = new Blob([byteNumbers], { type: "image/png" });

    // File 객체 생성 (name, lastModified, and type 지정)
    const file = new File([blob], "8156.png", {
      type: blob.type,
      lastModified: Date.now(),
    });

    return file;
  };

  // const base64ToBinary = (base64: string) => {
  //   // Base64 문자열을 디코딩
  //   const binaryString = atob(base64);

  //   // 바이너리 데이터를 Uint8Array로 변환
  //   const len = binaryString.length;
  //   const bytes = new Uint8Array(len);

  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }

  //   const blob = new Blob([bytes], { type: "image/png" });

  //   console.log("blob : ", blob);

  //   return blob;
  // };

  const getImageType = (base64: string) => {
    // Base64 문자열을 디코딩하여 바이트 배열로 변환
    const byteCharacters = atob(base64);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // 헤더를 확인하기 위해 첫 4바이트를 가져옴
    const header = byteNumbers.subarray(0, 4);

    // JPEG
    if (header[0] === 0xff && header[1] === 0xd8 && header[2] === 0xff) {
      return "image/jpeg";
    }
    // PNG
    if (
      header[0] === 0x89 &&
      header[1] === 0x50 &&
      header[2] === 0x4e &&
      header[3] === 0x47
    ) {
      return "image/png";
    }
    // GIF
    if (header[0] === 0x47 && header[1] === 0x49 && header[2] === 0x46) {
      return "image/gif";
    }
    // BMP
    if (header[0] === 0x42 && header[1] === 0x4d) {
      return "image/bmp";
    }
    // WEBP
    if (
      header[0] === 0x52 &&
      header[1] === 0x49 &&
      header[2] === 0x46 &&
      header[3] === 0x46
    ) {
      return "image/webp";
    }

    return "unknown";
  };

  /**상품 디테일 이미지 경로 가져오기 api*/
  const getSetProductDetailImageInfo = () => {
    GetSetProductDetailImageInfo(
      current_cookies,
      setInfodata.set_category_id,
      productData[0].set_name
    )
      .then((response) => {
        if (response.data.status_info.status_code === 200) {
          const responData = response.data.data;

          const imgType = responData.map((item: string) => {
            return getImageType(Object.values(item)[0]);
          });

          const parsedData = responData.map((item: any, index: number) => {
            return `data:${imgType[index]};base64,${Object.values(item)[0]}`;
          });

          setDetailImg(parsedData); //화면 표시용 base64 이미지

          const result = parsedData.map((item: string) => {
            return base64ToBinary(item);
          });

          setDetailImgData(result); //post,put 전송용 이미지
          // setDetailImgData() //post/put 전송용 binary 이미지
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          // alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  console.log("detailImgData : ", detailImgData);

  /**상품 디테일 리스트 가져오기 api*/
  const getSetProductImgList = async () => {
    // const list: any[] = [];
    // const imgUrlList: any[] = [];

    const list: any[] = new Array(5).fill(null);
    const imgUrlList: any[] = new Array(5).fill(null);

    try {
      const promises = detailImgPath.map(async (id, index) => {
        const response = await GetSetProductImgList(
          current_cookies,
          setInfodata.set_category_id,
          productData[0].set_name,
          Number(id)
        );
        const imageBlob = new Blob([response.data], {
          type: response.data.type,
        });
        const imageUrl = URL.createObjectURL(imageBlob);

        // Blob을 File 객체로 변환
        const file = new File([imageBlob], `image_${id}.jpg`, {
          type: response.data.type,
        });

        // 리스트에 파일 추가
        // list.push(file);
        list[Number(id) - 1] = file;

        // imgUrlList.push(imageUrl);
        imgUrlList[Number(id) - 1] = imageUrl;
      });

      // 모든 비동기 작업이 완료될 때까지 기다림
      await Promise.all(promises);

      // 화면용 디테일 이미지
      setDetailImg(imgUrlList);

      // 필요한 경우 데이터 전송용 이미지 설정
      setDetailImgData(list);
    } catch (error) {
      console.error("An error occurred while fetching images:", error);
    }
  };

  /**상품 기본 정보 PUT 업데이트 api */
  const putSetProductBaseInfo = (
    data: any,
    updateParams: any,
    formData: FormData
  ) => {
    PutSetProductBaseInfo(
      current_cookies,
      data,
      updateParams,
      originalCategoryId,
      originalSetName
    ).then((response) => {
      if (response.data.status_info.status_code === 200) {
        // navigate("/manage-set-product");
        putSetProductImg(formData);
      }
    });
  };

  // console.log("  productData[0].set_name : ", productData[0].set_name);

  /**상품 이미지 PUT 업데이트 api */
  const putSetProductImg = (formData: FormData) => {
    PutSetProductImg(
      current_cookies,
      formData,
      setInfodata.set_category_id,
      productData[0].set_name
    ).then((response) => {
      if (response.data.status_info.status_code === 200) {
        navigate("/manage-set-product");
        toast.success("성공적으로 업데이트되었습니다");
      }
    });
  };

  /**상품 기본 정보 POST 등록 api */
  const postSetProductBaseInfo = (data: any, params: any, formData: any) => {
    PostSetProductBaseInfo(current_cookies, data, params)
      .then((response) => {
        if (response.data.status_info.status_code === 201) {
          postSetProductImg(params, formData);
        }
      })
      .catch((error) => {
        console.log("error : ", error);
        if (error.response) {
          if (error.response.status === 422) {
            toast.error("양식을 완성해주세요");
          }
        }
      });
  };

  /**이미지 파일 변경 함수 */
  const handleFileChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];

      const fileName = file.name; //파일명

      if (fileName.length > 25) {
        toast.info("파일 이름은 25자를 초과할 수 없습니다.");
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (index === 0) {
            setImageFiles(reader.result as string);
            setUploadImageFiles(file);
          } else if (index === 1) {
            setImageFiles01(reader.result as string);
            setUploadImageFiles01(file);
          } else if (index === 2) {
            setImageFiles02(reader.result as string);
            setUploadImageFiles02(file);
          } else if (index === 3) {
            setImageFiles03(reader.result as string);
            setUploadImageFiles03(file);
          } else if (index === 4) {
            setImageFiles04(reader.result as string);
            setUploadImageFiles04(file);
          } else {
            setImageFiles05(reader.result as string);
            setUploadImageFiles05(file);
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const onHandleAddProduct = () => {
    setProductIdNumber(productIdNumber + 1);
    setProduct((prev: any) => {
      const prevState = [...prev];

      const data = {
        set_category_id: 0,
        set_created_at: "",
        set_name: "",
        set_prd_id: productIdNumber,
        set_prd_name: "",
        set_prd_standard: "",
        set_prd_quantity: "",
      };

      return [...prevState, data];
    });
  };

  const onHandleDeleteProduct = (id: string) => {
    setProduct((prev: any) => {
      const prevState = [...prev];

      const result = prevState.filter((item) => {
        return item.set_prd_id !== id;
      });

      return result;
    });
  };

  const onHandleResetImage = (index: number) => {
    if (index === 0) {
      setImageFiles(null);
      setThumbnail(null);
      setThumbnailData(null);
    } else if (
      index === 1 ||
      index === 2 ||
      index === 3 ||
      index === 4 ||
      index === 5
    ) {
      // 이미지 파일 상태를 null로 설정
      if (index === 1) {
        setImageFiles01(null);
      } else if (index === 2) {
        setImageFiles02(null);
      } else if (index === 3) {
        setImageFiles03(null);
      } else if (index === 4) {
        setImageFiles04(null);
      } else if (index === 5) {
        setImageFiles05(null);
      }

      // detailImg에서 해당 인덱스를 null로 설정
      setDetailImg((prev: any) => {
        const newState = [...prev];
        newState[index - 1] = null; // index가 1이면 0번째 인덱스, 2이면 1번째 인덱스를 null로 설정
        return newState;
      });

      // detailImgData에서 해당 인덱스를 null로 설정
      setDetailImgData((prev: any) => {
        const newState = [...prev];
        newState[index - 1] = null; // index가 1이면 0번째 인덱스, 2이면 1번째 인덱스를 null로 설정
        return newState;
      });
    }
  };

  useEffect(() => {
    // console.log("product 의 상태를 보자 ------ ", product);
  }, [product]);

  /**상품 업로드 */
  const onSubmit = (DATA: any) => {
    console.log("DATA : ", DATA);

    const filteredCategory = singlePrdList.filter(
      (item) => item.set_category_name === DATA.single_product
    );

    const params = {
      set_category_id: filteredCategory[0].set_category_id,
      set_name: DATA.set_product_name,
      set_box_count: DATA.set_box_count,
    };

    let data: any[] = [];
    let updateData: any[] = [];

    //product 에 DATA 데이터 할당
    if (product.length > 0) {
      for (let i = 0; i < product.length; i++) {
        product[i].set_prd_name = DATA[`product_name_${product[i].set_prd_id}`];
        product[i].set_prd_standard =
          DATA[`product_size_${product[i].set_prd_id}`];
        product[i].set_prd_quantity = Number(
          DATA[`product_count_${product[i].set_prd_id}`]
        );
      }

      //POST 용 product DeepCopy
      const product01 = JSON.parse(JSON.stringify(product));

      //POST 용 product 불필요한 키값 제거
      const postProduct = product01.filter((item: any) => {
        delete item.set_category_id;
        delete item.set_created_at;
        delete item.set_name;
        delete item.set_prd_id;

        Number(item.set_prd_quantity);
        return item;
      });

      //PUT 용 product DeepCopy
      const product02 = JSON.parse(JSON.stringify(product));

      // 키 값을 변경하는 함수
      const changeKeyName = (obj: any, oldKey: string, newKey: string) => {
        if (oldKey in obj) {
          const { [oldKey]: value, ...remainingData } = obj;
          return { ...remainingData, [newKey]: value };
        }
        return obj; // oldKey가 존재하지 않으면 원본 객체 반환
      };

      // product02의 각 항목에 대해 키 값을 변경
      const updatedProduct02 = product02.map((item: any) => {
        item = changeKeyName(item, "set_prd_name", "update_set_prd_name");
        item = changeKeyName(
          item,
          "set_prd_standard",
          "update_set_prd_standard"
        );
        item = changeKeyName(
          item,
          "set_prd_quantity",
          "update_set_prd_quantity"
        );
        return item;
      });

      //PUT 용 product 불필요한 키값 제거
      const putProduct = updatedProduct02.filter((item: any) => {
        delete item.set_category_id;
        delete item.set_created_at;
        delete item.set_name;

        if (item.set_prd_id === 0) {
          item.set_prd_id = null;
        }

        Number(item.set_prd_quantity);
        return item;
      });

      data = [
        {
          set_prd_name: DATA.product_name,
          set_prd_standard: DATA.product_size,
          set_prd_quantity: Number(DATA.product_count),
        },
        ...postProduct,
      ];

      updateData = [
        {
          set_prd_id: productData[0].set_prd_id,
          update_set_prd_name: DATA.product_name,
          update_set_prd_standard: DATA.product_size,
          update_set_prd_quantity: Number(DATA.product_count),
        },
        ...putProduct,
      ];
    } else {
      data = [
        {
          set_prd_name: DATA.product_name,
          set_prd_standard: DATA.product_size,
          set_prd_quantity: Number(DATA.product_count),
        },
      ];

      updateData = [
        {
          set_prd_id: productData[0].set_prd_id + 1,
          update_set_prd_name: DATA.product_name,
          update_set_prd_standard: DATA.product_size,
          update_set_prd_quantity: Number(DATA.product_count),
        },
      ];
    }

    //이미지 데이터
    const formData = new FormData();

    if (thumbnailData !== null || uploadImageFiles !== null) {
      formData.append(
        "set_prd_thumbnail_image",
        uploadImageFiles || thumbnailData
      );
    }

    if (detailImgData[0] || uploadImageFiles01) {
      formData.append(
        "set_prd_detail_image1",
        uploadImageFiles01 || detailImgData[0]
      );
    }

    if (detailImgData[1] || uploadImageFiles02) {
      formData.append(
        "set_prd_detail_image2",
        uploadImageFiles02 || detailImgData[1]
      );
    }

    if (detailImgData[2] || uploadImageFiles03) {
      formData.append(
        "set_prd_detail_image3",
        uploadImageFiles03 || detailImgData[2]
      );
    }

    if (detailImgData[3] || uploadImageFiles04) {
      formData.append(
        "set_prd_detail_image4",
        uploadImageFiles04 || detailImgData[3]
      );
    }

    if (detailImgData[4] || uploadImageFiles05) {
      formData.append(
        "set_prd_detail_image5",
        uploadImageFiles05 || detailImgData[4]
      );
    }

    if (originalCategoryId !== 0) {
      const updateParams = {
        set_category_id: filteredCategory[0].set_category_id,
        set_name: DATA.set_product_name,
        update_set_category_id: filteredCategory[0].set_category_id,
        update_set_name: DATA.set_product_name,
        update_set_box_count: DATA.set_box_count,
      };

      if (
        (uploadImageFiles !== null && uploadImageFiles01 !== null) ||
        (thumbnailData !== null && detailImgData[0] !== null)
      ) {
        putSetProductBaseInfo(updateData, updateParams, formData);
      } else {
        toast.error("썸네일과 첫번째 상세이미지를 등록해주세요");
      }
    } else {
      const stringIncluded = data.map((item) => {
        const isNotDigits = /[^0-9]/.test(item.set_prd_quantity); // 0-9 외의 문자가 있는지 확인

        return isNotDigits;
      });

      if (stringIncluded.includes(true)) {
        toast.error("매수는 숫자로 입력해주세요");
        // if (countRef.current) {
        //   countRef.current.focus(); //매수 입력란에 포커싱
        // }
      } else {
        if (uploadImageFiles !== null && uploadImageFiles01 !== null) {
          postSetProductBaseInfo(data, params, formData);
        } else {
          toast.error("썸네일과 첫번째 상세이미지를 등록해주세요");
        }

        // postSetProductImg(data, params);
      }
    }
  };

  const postSetProductImg = (params: any, formData: any) => {
    PostSetProductImg(current_cookies, formData, params)
      .then((response) => {
        if (response.data.status_info.status_code === 201) {
          navigate("/manage-set-product");
          toast.success("성공적으로 등록되었습니다");
        } else if (response.data.status_info.status_code === 400) {
          toast.error(response.data.status_info.message);
          // console.log(response.data.status_info.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 413) {
            toast.error("이미지 파일은 50mb 이하로 업로드 가능합니다");
          } else if (error.response.status === 422) {
            toast.error("양식을 완성해주세요");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.status_info.message);
          }
        }
      });
  };

  /**미리보기 */
  const handlePreview = () => {
    const dataValue = getValues();
    setPreviewData(dataValue);

    if (
      dataValue.single_product == undefined ||
      dataValue.product_count == undefined ||
      dataValue.product_name == undefined ||
      dataValue.product_size == undefined
    ) {
      alert("상품 정보를 모두 입력해주세요");
    } else if (imageFiles === null && thumbnail === null) {
      alert("썸네일을 등록해주세요");
    } else {
      setIsShowPreview(true);
    }
  };

  const [showInfo, setShowInfo] = useState<boolean>(false);

  const handleShowInfo = () => {
    setShowInfo(true);
  };

  const handleHideInfo = () => {
    setShowInfo(false);
  };

  const [setPrdName, setSetPrdName] = useState<string>("");

  return (
    <>
      {!isShowPreview ? (
        <PageWrap>
          <AdminHeader />
          <ContentWrap>
            <Title>
              세트 상품 업로드
              <p onMouseOver={handleShowInfo} onMouseLeave={handleHideInfo}>
                <RiInformation2Line size={24} color="#229CF0" />{" "}
                {showInfo && (
                  <ul>
                    <li>상품 업로드 가이드</li>
                    <li>
                      1) 썸네일과 첫 번째 상세 이미지 1개는 필수로
                      등록해야합니다.
                    </li>
                    <li>2) 이미지 파일 크기는 20MB 이하로 제한합니다.</li>
                    <li>
                      3) 이미지 확장자는 jpg, jpeg, png, gif로 제한합니다.
                    </li>
                    <li>4) 이미지 파일명은 30자 이하로 제한합니다.</li>
                    <li>5) 이미지 파일명에 특수문자를 제거해주세요.</li>
                    <li>
                      6) 썸네일 이미지 파일명에 thumbnail이 포함되지 않도록
                      해주세요.
                    </li>
                    <li>
                      7) 상세 이미지 파일명에 detail이 포함되지 않도록 해주세요.
                    </li>
                    <li>8) 세트명에 특수문자를 제거해주세요.</li>
                    <li>9) 세트명은 중복될 수 없습니다.</li>
                    <li>10) 세트의 구성품은 최소 1개 이상 등록해야합니다.</li>
                  </ul>
                )}
              </p>
            </Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormInfoWrap>
                <ProductInfoSection>
                  <div>
                    <FormControl
                      variant="filled"
                      size="small"
                      error={control.getFieldState("single_product").invalid}
                      required
                      style={{ width: "200px" }}
                    >
                      <InputLabel id="select-label">
                        세트 상품 카테고리
                      </InputLabel>
                      <Controller
                        name="single_product"
                        control={control}
                        rules={{ required: "옵션을 선택해주세요" }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <>
                            <Select
                              {...single_product}
                              labelId="select-label"
                              label="옵션"
                              defaultValue={
                                (singlePrdList &&
                                  singlePrdList[0]?.set_category_name) ||
                                ""
                              }
                            >
                              {singlePrdList.length > 0 ? (
                                singlePrdList.map((item, index) => {
                                  return (
                                    <MenuItem
                                      key={item.set_category_id}
                                      value={item.set_category_name}
                                    >
                                      {item.set_category_name}
                                    </MenuItem>
                                  );
                                })
                              ) : (
                                <MenuItem>
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "300",
                                      color: "#8e8e8e",
                                    }}
                                  >
                                    세트 카테고리 등록을 해주세요
                                  </p>
                                </MenuItem>
                              )}
                            </Select>
                            {error && (
                              <FormHelperText>{error.message}</FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                    <TextInput
                      fieldName="set_product_name"
                      label="세트 명"
                      control={control}
                      defaultValue={productData[0].set_name}
                      value={control._formValues}
                    />
                    <TextInput
                      fieldName="set_box_count"
                      label="박스 입수"
                      control={control}
                      defaultValue={productData[0].set_box_count}
                      value={control._formValues}
                    />
                  </div>
                  <button onClick={onHandleAddProduct} type="button">
                    구성품 추가 +
                  </button>
                </ProductInfoSection>
                <AddProductListSection>
                  <Product>
                    <p>구성품 등록</p>
                    <div>
                      <TextInput
                        fieldName="product_name"
                        label="품명"
                        control={control}
                        defaultValue={
                          productData && productData[0]?.set_prd_name
                        }
                      />
                      <TextInput
                        fieldName="product_size"
                        label="규격"
                        control={control}
                        defaultValue={
                          productData && productData[0]?.set_prd_standard
                        }
                      />
                      <TextInput
                        fieldName="product_count"
                        label="매수"
                        control={control}
                        defaultValue={
                          productData &&
                          productData[0]?.set_prd_quantity.toString()
                        }
                      />
                    </div>
                  </Product>
                  {product.map((item, index) => {
                    return (
                      <Product key={item.set_prd_id}>
                        {/** key 값을 item.id 와 같이 고유값으로 해야 해당 컴포넌트에 입력된 입력값이 잘 유지됨 */}
                        <p>구성품 등록</p>
                        <div>
                          <TextInput
                            fieldName={`product_name_${item.set_prd_id}`}
                            label="품명"
                            control={control}
                            defaultValue={item.set_prd_name || ""}
                          />
                          <TextInput
                            fieldName={`product_size_${item.set_prd_id}`}
                            label="규격"
                            control={control}
                            defaultValue={item.set_prd_standard || ""}
                          />
                          <TextInput
                            fieldName={`product_count_${item.set_prd_id}`}
                            label="매수"
                            control={control}
                            defaultValue={
                              item.set_prd_quantity.toString() || ""
                            }
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            onHandleDeleteProduct(item.set_prd_id);
                          }}
                        >
                          -
                        </button>
                      </Product>
                    );
                  })}
                </AddProductListSection>
                <ThumbNailSection>
                  <p>썸네일</p>
                  <div>
                    <FileUploadBtn
                      imageFiles={
                        thumbnail == null && imageFiles == null ? false : true
                      }
                    >
                      {imageFiles ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(0);
                            }}
                          >
                            x
                          </span>
                          <img src={imageFiles ? imageFiles : ""} />
                        </>
                      ) : thumbnail ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(0);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              thumbnail.toString() ? thumbnail.toString() : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 0);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                  </div>
                </ThumbNailSection>
                <DetailImgSection>
                  <p>상세 이미지</p>
                  <div>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[0] == null && imageFiles01 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles01 || detailImg[0] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(1);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles01
                                ? imageFiles01.toString()
                                : detailImg[0]
                                ? detailImg[0].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 1);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[1] == null && imageFiles02 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles02 || detailImg[1] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(2);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles02
                                ? imageFiles02.toString()
                                : detailImg[1]
                                ? detailImg[1].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 2);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[2] == null && imageFiles03 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles03 || detailImg[2] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(3);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles03
                                ? imageFiles03.toString()
                                : detailImg[2]
                                ? detailImg[2].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 3);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[3] == null && imageFiles04 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles04 || detailImg[3] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(4);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles04
                                ? imageFiles04.toString()
                                : detailImg[3]
                                ? detailImg[3].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 4);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[4] == null && imageFiles05 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles05 ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(5);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles05
                                ? imageFiles05.toString()
                                : detailImg[4]
                                ? detailImg[4].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 5);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                  </div>
                </DetailImgSection>
              </FormInfoWrap>
              <ButtonSection>
                <button type="button" onClick={handlePreview}>
                  미리보기
                </button>
                <button type="submit">등록</button>
              </ButtonSection>
            </Form>
          </ContentWrap>
        </PageWrap>
      ) : (
        <ProductDetailPreview
          data={previewData}
          thumbnail={imageFiles || thumbnail}
          setIsShowPreview={setIsShowPreview}
          detailImg={
            detailImg.length > 0
              ? detailImg
              : [
                  imageFiles01,
                  imageFiles02,
                  imageFiles03,
                  imageFiles04,
                  imageFiles05,
                ]
          }
          categorySetInfo={setInfodata}
          setPrdData={product}
        />
      )}
      {isCheckModal && (
        <CheckModal
          setIsCheckModal={setIsCheckModal}
          setHistoryCount={setHistoryCount}
          historyCount={historyCount}
        />
      )}
    </>
  );
}
