import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function GetSingleProduct(token: string, prd_id: string) {
  return axios.get(`${apiUrl}/api/admin/product/${prd_id}`, {
    // params: {
    //   prd_id,
    // },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
