import { Checkbox, Pagination } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useTable, Column, useRowSelect } from "react-table";
import { styled } from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #ebebeb;
    th {
      padding: 2px;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #b3b3b3;

      td {
        padding: 2px;
        font-weight: 400;
        font-size: 15px;
        color: #6b6b6b;

        a {
          color: #6b6b6b;
          text-decoration: underline;
        }
      }
    }
  }
`;

const PaginationSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

interface DataType {
  category: string;
  name: string;
  size: string;
  count: string;
  date: string;
}

interface Props {
  setPage: Dispatch<SetStateAction<number>>; //페이지 넘버 선택
  page: number; //페이지 넘버
  data: any;
  totalPage: number;
  useFor?: string; //테이블 사용 용도
  columnData: any;
  setSelectedRows?: any;
  setSelectedRow?: any;
}

export function CommonTable({
  setPage,
  page,
  data,
  totalPage,
  useFor,
  columnData,
  setSelectedRows,
  setSelectedRow,
}: Props) {
  //테이블 데이터를 정의
  /**
   * useMemo를 사용하지 않으면 매 렌더링마다 'data'가 새롭게 생성됨
   * 그렇게 되면 불필요한 재렌더링 발생 => 성능저하로 이어질 수 있음
   */
  // const data: object[] = React.useMemo(
  //   () => [
  //     {
  //       category: "장갑",
  //       name: "더 좋은 장갑",
  //       size: "LD24*7",
  //       count: "50매",
  //       date: "2024-04-29",
  //     },
  //   ],
  //   []
  // );

  // const newData: any[] = useMemo(() => data, []);

  const [setZeroLength, setSetZeroLength] = useState<boolean>(false);
  const [singleZeroLength, setSingleZeroLength] = useState<boolean>(false);

  const columns: Column<object>[] = React.useMemo(() => columnData, []);

  // 페이지 변경 핸들러
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    selectedFlatRows,
    prepareRow,
  } = useTable<object>({ columns, data }, useRowSelect, (hooks) => {
    //테이블 체크박스 작동
    hooks.visibleColumns.push((columns) => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: any) => (
          <div>
            <Checkbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ]);
  });

  useEffect(() => {
    if (data.length === 0) {
      if (useFor === "setProduct") {
        setSetZeroLength(true);
      } else if (useFor === "singleProduct") {
        setSingleZeroLength(true);
      }
    } else {
      setSetZeroLength(false);
      setSingleZeroLength(false);
    }
  }, [data]);

  // 선택된 행 출력
  useEffect(() => {
    const selectedRows = selectedFlatRows.filter((row) => row.original);
    setSelectedRows(selectedRows);
  }, [selectedFlatRows]);

  const handleRowClick = (row: any) => {
    // 클릭된 행의 원본 데이터에 접근
    setSelectedRow(row.original);
  };

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!setZeroLength && !singleZeroLength ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id}
                  onClick={() => {
                    handleRowClick(row);
                  }}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        ) : setZeroLength ? (
          <tbody>
            <tr>
              <td
                style={{ textAlign: "center", lineHeight: "50px" }}
                colSpan={6}
              >
                세트 상품이 없습니다
              </td>
            </tr>
          </tbody>
        ) : singleZeroLength ? (
          <tbody>
            <tr>
              <td
                style={{ textAlign: "center", lineHeight: "50px" }}
                colSpan={6}
              >
                상품이 없습니다
              </td>
            </tr>
          </tbody>
        ) : (
          <></>
        )}
      </Table>
      <PaginationSection>
        <Pagination
          count={totalPage}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChangePage}
        />
      </PaginationSection>
    </>
  );
}
