import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Control, useController, useForm } from "react-hook-form";

interface Props {
  fieldName: string;
  fieldStateName?: string;
  defaultValue?: string | any;
  label: string;
  control: Control;
  onChange?: (e: any) => void;
  value?: any;
}

export function TextInput({
  fieldName,
  fieldStateName,
  defaultValue,
  label,
  control,
  onChange,
  value,
}: Props) {
  const { field, fieldState } = useController({
    name: fieldName,
    control,
    // defaultValue: "",
  });

  // const [savedDefaultValue, setSavedDefaultValue] = useState<string>('')

  // useMemo(()=>{
  //   setSavedDefaultValue(defaultValue)
  // }, [defaultValue])

  useEffect(() => {
    if (defaultValue !== "") {
      field.onChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <TextField
      id="filled-basic"
      size="small"
      label={label}
      variant="filled"
      {...field}
      error={fieldState.invalid}
      required
      // value={value}
      // defaultValue={defaultValue}
      // onChange={onChange}
    />
  );
}
