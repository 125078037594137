import { keyframes, styled } from "styled-components";
import { Header } from "../../components/common/Header";
import bgImg from "../../assets/img/cleaneco_background.jpg";
import { useEffect, useState } from "react";
import { GetAllForLandingPage } from "../../api/client/GetAllForLandingPage";
import Cookies from "universal-cookie";
import { GetAllCategories } from "../../api/client/GetAllCategories";

const PageWrap = styled.div``;
const BannerSection = styled.div`
  width: 100%;
  height: 150px;
  background: url(${bgImg}) center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 150px;
  color: #fff;
  border-top: 2px solid #5bb331;

  h3 {
    font-size: 28px;
    font-weight: 300;
  }

  @media (max-width: 1000px) {
    h3 {
      font-size: 20px;
    }
  }
`;

const ContentSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ani = keyframes`
from{
  opacity: 0;
  margin-top:40px;
}to{
  opacity: 1;
  margin-top:0px;
}
`;

const HistoryWrap = styled.div`
  width: 760px;
  padding: 50px;
  box-sizing: border-box;
  position: relative;
  animation: ${ani} 1s;
  font-weight: 300;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 28%;
    top: 62px;
    height: 76%;
    width: 2px;
    background-color: #5bb331;
  }

  div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    & > div {
      width: 20%;
    }

    & + div {
      padding-top: 20px;
    }

    p {
      font-size: 45px;
      padding-right: 50px;
      color: #5bb331;
      width: 160px;
      /* transition: all 1s; */
    }
  }

  ul {
    width: 80%;
    font-size: 15px;
    line-height: 40px;
    padding-bottom: 20px;
    margin-left: 20px;
    border-bottom: 2px dashed #d7d7d7;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -50px;
      top: 12px;
      width: 13px;
      height: 13px;
      border-radius: 30px;
      background-color: #5bb331;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;

    &::before {
      left: 38%;
      height: 80%;
      top: 54px;
      display: none;
    }

    div {
      p {
        font-size: 30px;
      }

      ul {
        width: 100%;
        font-size: 13px;
      }
    }
  }
`;

export function CompanyHistory() {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const [categoryName, setCategoryName] = useState<any[]>([]);

  useEffect(() => {
    // getAllForLandingPage();
    getAllCategories();
  }, []);

  const getAllForLandingPage = () => {
    GetAllForLandingPage(current_cookies).then((response) => {
      const prdList = response.data.data.single_category_prd_list;
      const setPrdList = response.data.data.set_category_set_name_list;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  const params = {
    order_by: "category_id",
    order_type: "asc",
    set_order_by: "set_category_id",
    set_order_type: "asc",
  };

  const getAllCategories = () => {
    GetAllCategories(current_cookies, params).then((response) => {
      const prdList = response.data.data.categories_info.categories;
      const setPrdList = response.data.data.set_categories_info.set_categories;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  return (
    <PageWrap>
      <Header isWhiteLogo={false} categoryName={categoryName} />
      <BannerSection>
        <h3>크린에코 연혁</h3>
      </BannerSection>
      <ContentSection>
        <HistoryWrap>
          <div>
            <p>2013</p>
            <ul>
              <li>하나로마트 거래처 등록</li>
              <li>주식회사 크린에코 상호변경</li>
              <li>본사 확장이전(포천시)</li>
            </ul>
          </div>
          <div>
            <p>2011</p>
            <ul>
              <li>롯데 알미륨 납품</li>
            </ul>
          </div>
          <div>
            <p>2010</p>
            <ul>
              <li>한국 다이소 납품</li>
              <li>교원 납품</li>
              <li>코카콜라 납품</li>
              <li>미스터 피자 납품</li>
              <li>피자헛 납품</li>
            </ul>
          </div>
          <div>
            <p>2009</p>
            <ul>
              <li>에코크린 창업</li>
            </ul>
          </div>
        </HistoryWrap>
      </ContentSection>
    </PageWrap>
  );
}
