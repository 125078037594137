// import axios from "axios";
// const apiUrl = process.env.REACT_APP_API_URL;

// export function GetAllForLandingPage(token: string) {
//   return axios.get(`${apiUrl}/api/client/all-for-landing-page`, {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//   });
// }

import axios from "axios";
import LZString from "lz-string";

const apiUrl = process.env.REACT_APP_API_URL;

export function GetAllForLandingPage(token: string) {
  const etagKey = "landing_etag"; // E-Tag 키를 landing_etag로 변경
  const etag = sessionStorage.getItem(etagKey); // sessionStorage에서 해당 API의 E-Tag 가져오기

  return axios
    .get(`${apiUrl}/api/client/all-for-landing-page`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "If-None-Match": etag || "", // E-Tag가 있으면 If-None-Match에 추가
      },
      validateStatus: (status) => {
        // 304 상태 코드는 에러가 아니므로 정상 응답으로 처리
        return status >= 200 && status < 400; // 200-399 범위에서 정상 응답
      },
    })
    .then((response) => {
      // 304 상태 코드 확인 (변경 없음)
      if (response.status === 304) {
        console.log("No changes in landing page data. Using cached data.");

        // sessionStorage에서 압축된 데이터 가져오기
        const compressedCachedData = sessionStorage.getItem(
          `cached_${etagKey}_response`
        );
        if (compressedCachedData) {
          // decompress 처리
          const decompressedData = LZString.decompress(compressedCachedData);
          return { data: JSON.parse(decompressedData) }; // 캐시된 데이터를 반환
        } else {
          console.error("No cached data found.");
          return { data: null }; // 캐시가 없을 경우, 새 데이터를 받아야 할 수 있음
        }
      }

      // 새로운 E-Tag가 응답 헤더에 있으면 저장
      const newEtag = response.headers["e-tag"];
      if (newEtag) {
        sessionStorage.setItem(etagKey, newEtag); // 최신 E-Tag 저장

        // 데이터를 압축하여 sessionStorage에 저장
        const originalData = JSON.stringify(response.data);
        // 압축 전 데이터 크기 확인 (디버그용)
        console.log("Original Data Size:", originalData.length);

        const compressedData = LZString.compress(originalData);
        // 압축 후 데이터 크기 확인 (디버그용)
        console.log("Compressed Data Size:", compressedData.length);

        sessionStorage.setItem(`cached_${etagKey}_response`, compressedData); // 압축된 데이터를 sessionStorage에 저장
      }

      return response;
    })
    .catch((error) => {
      console.error("Error fetching landing page data:", error);
      throw error;
    });
}
