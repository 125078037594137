import { keyframes, styled } from "styled-components";
import { Header } from "../../components/common/Header";
import bgImg from "../../assets/img/cleaneco_background.jpg";
import { GetAllForLandingPage } from "../../api/client/GetAllForLandingPage";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { GetAllCategories } from "../../api/client/GetAllCategories";

const PageWrap = styled.div``;
const BannerSection = styled.div`
  width: 100%;
  height: 150px;
  background: url(${bgImg}) center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 150px;
  color: #fff;
  border-top: 2px solid #5bb331;

  h3 {
    font-size: 28px;
    font-weight: 300;
  }

  @media (max-width: 1000px) {
    h3 {
      font-size: 20px;
    }
  }
`;

const ContentWrap = styled.div`
  width: 760px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: auto;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const ani = keyframes`
from{
  opacity: 0;
  margin-top:40px;
}to{
  opacity: 1;
  margin-top:0px;
}
`;

const TitleSection = styled.div`
  animation: ${ani} 1s;
  padding: 30px 0 15px;
  font-size: 22px;
  line-height: 41px;
  border-bottom: 3px solid #5bb331;
  margin-bottom: 20px;
  font-weight: 300;

  span {
    color: #5bb331;
  }
`;

const TextSection = styled.div`
  animation: ${ani} 1s;
  font-weight: 300;
  h4 {
    font-size: 24px;
    line-height: 50px;
    padding: 30px 0 10px 0;
    color: #5bb331;
    position: relative;
    line-height: 30px;
    padding-left: 30px;
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #5bb331;
      border-radius: 1px;
      position: absolute;
      left: 0;
      top: 38px;
    }
  }

  & > p {
    color: #898989;
    line-height: 36px;
    padding-top: 15px;
    /* text-indent: 20px; */
  }

  div {
    margin-top: 20px;
    background-color: #f2f2f2;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: 30px;
    box-sizing: border-box;

    &.last_textbox {
      margin-top: 0px;
    }

    p {
      line-height: 30px;
      color: #797979;
    }
  }
`;

const DivideSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;

  p {
    height: 31px;
    width: 2px;
    background: #cbcbcb;
  }
`;

const BottomTextSection = styled.div`
  margin-top: 50px;
  font-weight: 300;
  height: 150px;
  margin-bottom: 50px;
  line-height: 43px;
  color: #444;
  font-size: 18px;
`;

export function CeoIntroduce() {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const [categoryName, setCategoryName] = useState<any[]>([]);

  useEffect(() => {
    // getAllForLandingPage();
    getAllCategories();
  }, []);

  const getAllForLandingPage = () => {
    GetAllForLandingPage(current_cookies).then((response) => {
      const prdList = response.data.data.single_category_prd_list;
      const setPrdList = response.data.data.set_category_set_name_list;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  const params = {
    order_by: "category_id",
    order_type: "asc",
    set_order_by: "set_category_id",
    set_order_type: "asc",
  };

  const getAllCategories = () => {
    GetAllCategories(current_cookies, params).then((response) => {
      const prdList = response.data.data.categories_info.categories;
      const setPrdList = response.data.data.set_categories_info.set_categories;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  return (
    <PageWrap>
      <Header isWhiteLogo={false} categoryName={categoryName} />
      <BannerSection>
        <h3>CEO 인사말</h3>
      </BannerSection>
      <ContentWrap>
        <TitleSection>
          <p>
            안녕하십니까? <br /> <span>크린에코</span> 홈페이지를 방문해 주셔서
            진심으로 감사드립니다.
          </p>
        </TitleSection>
        <TextSection>
          <h4>크린에코 소개</h4>
          <p>
            크린에코는 2009년 개인사업자 에코크린으로 출발하여,
            <br /> 2013년에 법인회사로 전환하면서 지속적인 성장을
            이루어왔습니다. <br /> 저희는 위생장갑, 위생롤백, 지퍼백, 위생백 등
            다양한 제품군을 통해 <br /> 고객 여러분께 탁월한 품질과 혁신적인
            솔루션을 제공하기 위해 항상 노력하고 있습니다.
          </p>
        </TextSection>
        <DivideSection>
          <p></p>
        </DivideSection>
        <TextSection>
          <h4>통합시스템을 통한 엄격한 관리</h4>
          <p>
            저희의 강점은 원단 생산에서 가공, 포장, 물류까지 모든 과정을 엄격히
            관리하는 통합 시스템입니다.
            <br /> 이를 통해 고객의 다양한 요구에 신속하고 정확하게 대응하며,
            최상의 품질을 보장합니다.
            <br /> 크린에코의 제품은 대기업, 대형 유통업체, 공공기관 등 여러
            분야에서 높은 신뢰를 받고 있으며,
            <br /> 품질, 납기, 가격 모든 면에서 고객 만족을 실현하고 있습니다.
          </p>
        </TextSection>
        <DivideSection>
          <p></p>
        </DivideSection>
        <TextSection>
          <h4>크린에코의 핵심 가치</h4>
          <p>
            크린에코는 "정직", "신뢰", "지속 가능성"을 핵심 가치로 삼고
            있습니다.
            <br /> 저희는 정직한 경영을 바탕으로 고객과의 신뢰를 구축하고
            있으며,
            <br /> 지속 가능한 제품 개발을 통해 환경과 조화를 이루는 기업이 되기
            위해 노력하고 있습니다.
            <br /> 이러한 가치들은 저희가 하는 모든 일에 깊이 뿌리내려 있으며,
            앞으로도 변함없이 지켜나갈 것입니다.
          </p>
        </TextSection>
        <DivideSection>
          <p></p>
        </DivideSection>
        <TextSection>
          <h4>고객과의 약속</h4>
          <p>
            고객 여러분의 성원과 신뢰에 깊이 감사드리며, 앞으로도 크린에코는
            혁신과 품질을 바탕으로
            <br /> 고객의 삶의 질을 높이는 제품을 제공하기 위해 최선을
            다하겠습니다.
            <br /> 저희의 노력은 고객의 신뢰와 만족을 위한 것입니다. 언제나
            최고의 품질과 서비스를 제공할 것을 약속드립니다.
          </p>
        </TextSection>
        <BottomTextSection>
          감사합니다.
          <br />
          크린에코 대표 이현규
        </BottomTextSection>
      </ContentWrap>
    </PageWrap>
  );
}
