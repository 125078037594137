import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function GetSetProductDetailImageInfo(
  token: string,
  set_category_id: number,
  set_name: string
) {
  return axios.get(
    `${apiUrl}/api/admin/set-product/detail-images/${set_category_id}/${set_name}`,
    {
      headers: {
        "Content-Type": "application/json",
        // "Cache-Control": "no-cache", //이미지 데이터 캐싱 삭제
        Authorization: token,
      },
      //   responseType: "blob", //백에서 바이너리로 주는걸 Blob 으로 변환해서 받아야 함
    }
  );
}
