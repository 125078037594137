import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  set_category_id: number;
  set_name: string;
}

export function PostSetProductImg(
  token: string,
  formData: FormData | any,
  params: Props
) {
  //data: any
  return axios.post(`${apiUrl}/api/admin/set-product/image`, formData, {
    params: {
      set_category_id: params.set_category_id,
      set_name: params.set_name,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  });
}
