import react, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled, { keyframes } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logoWhite from "../../assets/img/cleaneco_logo-white.png";
import bgImg from "../../assets/img/cleaneco_background.jpg";
import greenBgImg from "../../assets/img/cleaneco_green_bgImg.png";
import workProcess from "../../assets/img/work_process_.png";
// import workProcess from "../../assets/img/work_process_2.png";
import ArrowLong from "../../assets/icon/Arrow_long.png";
// import BussinessPolicy from "../../assets/img/landing/business_policy.jpg";
import BussinessPolicy from "../../assets/img/landing/business_policy2.png";
import QualityPolicy from "../../assets/img/landing/quality_policy.png";
import EcoPolicy from "../../assets/img/landing/eco_policy.png";
import PlantIcon from "../../assets/icon/plant.png";
import PaperIcon from "../../assets/icon/paper.png";
import HandIcon from "../../assets/icon/hand.png";
import PackIcon from "../../assets/icon/pack.png";
import ZipperBagIcon from "../../assets/icon/zipper_bag_icon.png";
import PlasticRollIcon from "../../assets/icon/plastic_roll_icon.png";
import KitchenIcon from "../../assets/icon/kitchen_icon.png";
import HomeIcon from "../../assets/icon/home_icon.png";
import KimchiIcon from "../../assets/icon/kimchi_icon.png";
import CompanyPolicyIcon from "../../assets/icon/company_policy.png";
import ProductPolicyIcon from "../../assets/icon/product_policy.png";
import EcoPolicyIcon from "../../assets/icon/eco_policy.png";
import BetterIcon from "../../assets/icon/better_icon.png";
import ArrowIcon from "../../assets/icon/arrow_icon.png";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/common/Header";
import { GetAllForLandingPage } from "../../api/client/GetAllForLandingPage";
import Cookies from "universal-cookie";
import { RiBuildingLine } from "@remixicon/react";
import { RiCheckLine } from "@remixicon/react";
import { RiMapPin2Fill } from "@remixicon/react";
import { RiPhoneLine } from "@remixicon/react";
import { GetProductThumbnailImg } from "../../api/client/GetProductThumbnailImg";
import { GetSetProductThumbnail } from "../../api/product/set/GetSetProductThumbnail";
import { GetProductSetThumbnailImg } from "../../api/client/GetProductSetThumbnailImg";
import image01 from "../../assets/img/production_process/image01.jpeg";
import image02 from "../../assets/img/production_process/image02.jpeg";
import image03 from "../../assets/img/production_process/image03.jpeg";
import image04 from "../../assets/img/production_process/image04.jpeg";
import { GetAllCategories } from "../../api/client/GetAllCategories";

const PageWrap = styled.div``;

const HeaderSection = styled.div`
  width: 100%;
  background: url(${bgImg}) center no-repeat;
  background-size: cover;
  /* height: 80vh; */
  height: 71vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* header {
    width: auto;
    padding: 10px 40px 0;
    display: flex;
    justify-content: space-between;
  } */
`;
const Logo = styled.div`
  width: auto;
  height: 80px;
  img {
    height: 100%;
  }
`;
const Nav = styled.nav`
  & > ul {
    width: 150px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    & > li {
      cursor: pointer;
      position: relative;

      div {
        position: absolute;
        top: 30px;
        left: 0;
        width: 200px;
        p {
          font-size: 15px;
          color: #cbcbcb;
          & + p {
            margin-top: 5px;
          }

          a {
            color: #b9b9b9;
          }
        }
      }
    }
  }
`;

const IntroTextAni = keyframes`
from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
  }
`;

const IntroText = styled.div`
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 60px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 45px;
    color: #5bb331;
    white-space: nowrap;
    overflow: hidden;
    animation: ${IntroTextAni} 3s ease-in-out;
  }

  p {
    font-size: 25px;
    font-weight: 200;
    color: #cbcbcb;
    white-space: nowrap;
    overflow: hidden;
    animation: ${IntroTextAni} 5s ease-in-out;
  }

  @media (max-width: 1024px) {
    line-height: 30px;
    h3 {
      font-size: 18px;
    }

    p {
      font-size: 15px;
    }
  }
`;

const SubNav = styled.ul`
  padding: 0 150px;
  width: auto;
  display: flex;

  li {
    width: 33%;
    text-align: center;
    height: 60px;
    line-height: 60px;
    color: #cbcbcb;
    box-sizing: border-box;
    border: 1px solid #cbcbcb;
    background-color: rgba(0, 0, 0, 0.1);

    &:first-child {
      color: #5bb331;
      border-color: #5bb331;
    }
  }
`;

const Section_01 = styled.div`
  width: 100%;
  height: 85vh;
  min-height: 700px;
  display: flex;
  justify-content: center;
  background-color: #dfdfdf;

  @media (max-width: 1024px) {
    min-height: 0;
  }
`;

const slickAni = keyframes`
    from{
      opacity:0;
    }
    to{
      opacity:100;
    }
`;

const Section_01Wrap = styled.div`
  padding-top: 100px;
  /* width: 80%; */
  width: 70%;
  height: auto;

  .slick-slider {
    .slick-prev,
    .slick-next {
      width: 50px;
      height: 60px;
      background-color: #5bb331;
    }

    .slick-prev {
      left: -70px;
    }

    .slick-next {
      right: -70px;
    }
  }

  .slick-dots {
    animation: ${slickAni} 2s ease-in-out;

    li {
      width: 33%;
      height: 60px;
      line-height: 60px;
      margin: 0;
      color: #cbcbcb;
      border: 1px solid #cbcbcb;
      background-color: rgba(0, 0, 0, 0.2);

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }

      svg {
        /* vertical-align: -5px;
          padding-right: 10px; */

        margin-top: -6px;
        padding-right: 10px;
      }

      &.slick-active {
        color: #5bb331;
        border: 1px solid #5bb331;
      }
    }
  }

  .slick-next:before {
    content: ">";
  }
  .slick-prev:before {
    content: "<";
  }

  @media (max-width: 1024px) {
    width: 100%;

    .slick-slider {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
  }
`;

const SlideItem = styled.div`
  height: 500px;
  background-color: #dfdfdf;
  & > div {
    width: 70%;
    height: 100%;
    /* background-color: #aaa; */

    position: relative;

    img {
      width: 100%;
    }

    .textcontent {
      /* width: 50%; */
      width: 53%;
      height: 70%;
      position: absolute;
      /* right: -20%; */
      right: -21%;
      top: 15%;
      background-color: #fff;
      z-index: 1;
      padding: 50px;
      box-sizing: border-box;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -5%;
        /* top: 30px; */
        top: 55px;
        width: 50px;
        height: 15px;
        background-color: #5bb331;
      }
      p:first-child {
        color: #52a52b;
        font-size: 25px;
        font-weight: 800;
        padding-bottom: 20px;
      }

      p:last-child {
        color: #919191;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }
  @media (max-width: 1024px) {
    & > div {
      width: 80%;

      /* width: 100%; */
      margin: 0 auto;
      height: 84%;

      .textcontent {
        width: 100%;
        padding: 25px;
        height: 60%;
        left: 0;
        top: 44%;
        /* margin-top: 20px; */

        p:first-child {
          font-size: 14px;
        }

        p:last-child {
          font-size: 12px;
        }
      }
    }
  }
`;

const Section_02 = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    margin: 35px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
      font-size: 28px;
      /* padding-bottom: 15px; */
      padding-bottom: 25px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    width: auto;
    margin: 26px;
    & > div {
      width: 100%;
      margin: 0;
      /* margin-left: 20px; */
      margin-top: 20px;

      h4 {
        font-size: 1.4rem;
        padding-top: 22px;
      }
    }
  }
`;

const Section_02_List = styled.div`
  ul {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 500px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100px;
      cursor: pointer;

      &.img_listitem {
        cursor: pointer;
        /* &:first-child p:first-child::before {
          display: none;
        } */

        & > p:first-child {
          width: 100px;
          height: 75px;
          position: relative;
          margin-bottom: 10px;

          img {
            width: 100%;
            height: 100%;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #5bb331;
            opacity: 0.6;
          }
        }

        @media (max-width: 1024px) {
          & > p:first-child {
            width: 100%;
          }
        }
      }

      &.workprocess_active {
        & > p:first-child {
          &::before {
            content: "";
            opacity: 0;
            transition: all 0.3s;
          }
        }
      }

      & > img {
        margin-top: 15px;
        width: 38px;
      }

      .icon {
        img {
          width: 89px;
          /* margin-top: -25px; */
        }
      }

      .text {
        /* color: #418721; */
        font-weight: 300;
        font-size: 14px;

        @media (max-width: 500px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 50px;
        }
      }

      span {
        display: block;
        width: 20px;
        height: 20px;
        background-color: #5bb331;
        border-radius: 50px;
      }
    }
  }

  @media (max-width: 1024px) {
    ul {
      width: 100%;
    }
  }
`;

const ImgBox = styled.div`
  position: relative;

  padding: 0;
  margin: 35px;
  width: 25%;
  height: 63%;

  span.green-element {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #5bb331;
  }

  p {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: -20px;
    left: -20px;

    img {
      height: 100%;

      &.img-fade {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }

      &.img-fade-hidden {
        opacity: 0;
      }
    }
  }
`;
const TextBox = styled.div`
  /* width: 50%; */
  width: 600px;
  margin-left: 40px;
  height: 55%;

  & > div {
    h4 {
      color: #5bb331;
    }
    p {
      font-weight: 100;
      line-height: 30px;
    }
  }
`;

const Section_03 = styled.div`
  width: 100%;
  background: url(${greenBgImg}) center no-repeat;
  background-size: cover;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    overflow: hidden;
    height: auto;
  }
`;

const TitleBox = styled.div`
  padding-top: 50px;
  text-align: center;
  color: #fff;
  line-height: 55px;
  h4 {
    font-size: 40px;

    img {
      vertical-align: -10px;
      padding-right: 15px;
      width: 50px;
    }
  }
  p {
    font-size: 20px;
  }

  @media (max-width: 1024px) {
    h4 {
      font-size: 25px;
    }

    p {
      font-size: 14px;
    }
  }
`;

const ani = keyframes`
from{
  margin-top:20px;
  opacity: 0;
}to{
  margin-top:5px;
  opacity: 1;
}

`;

const ContentBox = styled.div`
  /* width: 75%; */
  width: 60%;
  padding-top: 50px;

  & > ul {
    display: flex;
    /* justify-content: flex-start; */
    /* justify-content: space-around; */
    justify-content: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 40px;

    & > li {
      width: 90px;
      height: 90px;
      border: 1px solid #fff;
      border-radius: 20px;
      text-align: center;
      /* line-height: 90px; */
      color: #fff;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      padding: 10px;
      box-sizing: border-box;

      & + li {
        margin-left: 50px;

        @media (max-width: 1024px) {
          margin-left: 24px;
        }
      }

      img {
        width: 33px;
        padding-bottom: 10px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  & > div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    div {
      ul {
        @media (max-width: 1024px) {
          height: 137px;
          overflow: hidden;
          margin-bottom: 30px;
        }

        li {
          display: inline-block;
          margin: 5px;
          font-size: 14px;
          color: #fff;
          text-align: center;

          cursor: pointer;
          transition: all 0.3s;
          /* margin-top: 50px; */
          animation: ${ani} 0.3s;

          img {
            width: 80px;
            border-radius: 10px;
            box-sizing: border-box;
            border: 4px solid #4e962b;
          }

          p {
            border: 1px solid #fff;
            background-color: #4e962b;
            padding: 5px 10px;
            border-radius: 20px;
            box-sizing: border-box;
            margin-top: 10px;
          }
        }

        li + li {
          /* margin-left: 10px; */
        }
      }

      p {
        margin-top: 50px;
        color: #fff;
        font-size: 15px;
        font-weight: 300;
      }
    }

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: none;
      background-color: transparent;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      padding-top: 30px;
      padding: 20px;
      box-sizing: border-box;
      transition: all 0.3s;

      &:hover {
        background-color: #58a134;
      }

      span {
        display: block;
        text-indent: 15px;
        padding-bottom: 10px;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 90%;

    & > ul {
      width: auto;
      overflow-y: scroll;

      li {
        overflow: visible;
      }
    }

    & > div {
      display: block;
    }
  }
`;

const Section_04 = styled.div`
  width: 100%;
  background: #fff;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    color: #444;
  }

  & > div {
    padding-top: 50px;
    width: 50%;
  }

  @media (max-width: 1024px) {
    & > div {
      width: 90%;
    }
  }
`;

const Address = styled.p`
  padding-top: 20px;
`;

const Footer = styled.div`
  width: 100%;
  height: 300px;
  background-color: #3c4a65;
  font-weight: 300;

  @media (max-width: 1024px) {
    height: auto;
  }
`;

const FooterTop = styled.div`
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;

    & > p {
      height: 150px;
      img {
        height: 100%;
      }
    }

    & > div {
      line-height: 25px;
      color: #fff;
      padding-left: 200px;
      padding-top: 37px;

      div {
        padding-top: 21px;
        display: flex;
        justify-content: flex-start;

        p:last-child {
          padding-left: 41px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    height: auto;
    width: auto;
    padding: 25px;
    & > div {
      width: auto;
      padding: 20px;
      margin: 0;
      display: block;
      & > p {
        height: 101px;
      }
      & > div {
        padding: 0;
        padding-top: 32px;
      }
    }
  }
`;

const FooterBottom = styled.div`
  background-color: #363b46;
  color: #fff;
  height: 80px;
  line-height: 80px;
  font-size: 100;
  & > div {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      line-height: normal;
      margin: 0;
      width: 100%;

      p {
        white-space: nowrap;
        width: 100%;
        text-align: center;
        font-size: 13px;
      }
    }
  }
`;

export function Landing() {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const navigate = useNavigate();

  const sliderPageText = ["경영방침", "품질방침", "환경방침", "더미"];
  const slidePageIcon = [CompanyPolicyIcon, ProductPolicyIcon, EcoPolicyIcon];

  const ImgList = [
    HandIcon,
    PackIcon,
    ZipperBagIcon,
    PlasticRollIcon,
    HomeIcon,
    KitchenIcon,
    BetterIcon,
    KimchiIcon,
  ];

  const [imgUrl, setImgUrl] = useState<any[]>([]);

  const workProcessListRef = useRef<HTMLLIElement[]>([]); //작업과정 리스트 ref

  const slider_settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    // cssEase: "linear",
    appendDots: (dots: any) => (
      <div
        style={{
          position: "absolute",
          top: "-162px", // 위치를 조정하세요
          width: "100%",
        }}
      >
        <ul
          style={{
            margin: "0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i: any) => (
      <div
        style={{
          width: "100%",
        }}
      >
        {/* <img
          src={slidePageIcon[i]}
          style={{ verticalAlign: "-5px", paddingRight: "10px" }}
        /> */}
        <RiCheckLine size={24} />
        {sliderPageText[i]}
      </div>
    ),
  };

  const [isNavListShow, setIsNavListShow] = useState<boolean>(false);
  // const [categoryName, setCategoryName] = useState<Category>(CATEGORY_LIST[0]);
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const [categoryUrlName, setCategoryUrlName] = useState<any[]>([]);
  const [categorySetName, setCategorySetName] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [productSetList, setProductSetList] = useState<any[]>([]);
  const liRefs = useRef<(HTMLLIElement | null)[]>([]);
  const productListRef = useRef<(HTMLLIElement | null)[]>([]);

  const [clickedSetId, setClickedSetId] = useState<number>(0);
  const [categoryNameString, setCategoryNameString] = useState<string>("");

  useEffect(() => {
    /**크린에코 대표제품들 첫 상품 배경 CSS 설정 */

    getAllForLandingPage();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (liRefs.current) {
      if (liRefs.current[0])
        liRefs.current[0].style.backgroundColor = "rgba(255,255,255,0.2)";
    }

    if (categoryName[0]) {
      if (categoryName[0]?.prd_list) {
        setProductList(categoryName[0]?.prd_list);
      } else {
        setProductList(categoryName[0]?.set_name_list);
      }
    }
    setCategoryNameString(categoryName[0]?.category_id);
  }, [categoryName]);

  /**
   * 카테고리에 따른 상품리스트 표시
   * @param e
   * @param item
   */
  const onShowProductList = (e: React.MouseEvent, item: any) => {
    if (liRefs.current) {
      liRefs.current.forEach((li) => {
        if (li) li.style.backgroundColor = "transparent";
      });
    }
    const currentItem = e.currentTarget as HTMLLIElement;
    currentItem.style.backgroundColor = "rgba(255,255,255,0.2)";

    setCategoryNameString(item.category_id);

    if (item.prd_list) {
      setProductList(item.prd_list);
    } else if (item.set_name_list) {
      setProductList(item.set_name_list);
      setClickedSetId(item.set_category_id);
    }
  };

  const [animKey, setAnimKey] = useState(0);

  useEffect(() => {
    setAnimKey(animKey + 1);

    const fetchThumbnails = async () => {
      const promises = productList.map((item: any, index: number) => {
        if (item.prd_id !== undefined) {
          // getSingleProductThumbnail(item.prd_id, index);
        } else if (item.set_name !== undefined) {
          // getSetProductThumbnail(item.set_name, index);
        }
      });

      await Promise.all(promises);
    };

    fetchThumbnails();

    // const fetchSetThumbnails = async () => {
    //   const promises = productList.map((item: any, index: number) => {
    //     getSetProductThumbnail(item.set_name, index);
    //   });

    //   await Promise.all(promises);
    // };

    // fetchSetThumbnails();
  }, [productList]);

  const onShowSetProductList = (e: React.MouseEvent, item: any) => {
    if (liRefs.current) {
      liRefs.current.forEach((li) => {
        if (li) li.style.backgroundColor = "transparent";
      });
    }
    const currentItem = e.currentTarget as HTMLLIElement;
    currentItem.style.backgroundColor = "rgba(255,255,255,0.2)";

    // const selectedCategory = e.currentTarget.textContent as any;
    // setCategoryName(selectedCategory);
    setProductSetList(item.set_name_list);
  };

  const getAllForLandingPage = () => {
    GetAllForLandingPage(current_cookies).then((response) => {
      const prdList = response.data.data.single_category_prd_list;
      const setPrdList = response.data.data.set_category_set_name_list;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  const params = {
    order_by: "category_id",
    order_type: "asc",
    set_order_by: "set_category_id",
    set_order_type: "asc",
  };

  const getAllCategories = () => {
    GetAllCategories(current_cookies, params).then((response) => {
      const prdList = response.data.data.categories_info.categories;
      const setPrdList = response.data.data.set_categories_info.set_categories;

      setCategoryUrlName([...prdList, ...setPrdList]);
    });
  };

  /**
   * 단일상품 썸네일 이미지 조회 api
   * @param prd_id
   * @param index
   */
  const getSingleProductThumbnail = async (prd_id: string, index: number) => {
    // const imgUrlList: any[] = [];
    console.log("current_cookies : ", current_cookies);

    const response = await GetProductThumbnailImg(current_cookies, prd_id);

    const imageBlob = new Blob([response.data], {
      type: response.data.type,
    });

    const imageUrl = URL.createObjectURL(imageBlob);

    // imgUrlList[index] = imageUrl;

    setImgUrl((prevImgUrl) => {
      const newImgUrlList = [...prevImgUrl];
      newImgUrlList[index] = imageUrl;
      return newImgUrlList;
    });
  };

  /**
   * 세트상품 썸네일 이미지 조회 api
   * @param set_name
   * @param index
   */
  const getSetProductThumbnail = async (set_name: string, index: number) => {
    const response = await GetProductSetThumbnailImg(
      current_cookies,
      clickedSetId.toString(),
      set_name
    );

    const imageBlob = new Blob([response.data], {
      type: response.data.type,
    });

    const imageUrl = URL.createObjectURL(imageBlob);
    setImgUrl((prevImgUrl) => {
      const newImgUrlList = [...prevImgUrl];
      newImgUrlList[index] = imageUrl;
      return newImgUrlList;
    });
  };

  // const mapContainer = document.getElementById("map");

  useEffect(() => {
    // 카카오 지도 API가 이미 로드되었는지 확인
    const loadKakaoMap = () => {
      if (window.kakao && window.kakao.maps) {
        initializeMap(); // 이미 로드된 경우 바로 초기화
      } else {
        const script = document.createElement("script");
        script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=903f26be52b6ad5f030bfea4588fca73`;
        script.onload = () => {
          window.kakao.maps.load(() => {
            initializeMap(); // 스크립트 로드 후 지도를 초기화
          });
        };
        document.head.appendChild(script);
      }
    };

    const initializeMap = () => {
      const mapContainer = document.getElementById("map"); // 지도를 표시할 div
      const mapOption = {
        center: new window.kakao.maps.LatLng(37.793954074365, 127.235845842662), // 서울의 위도, 경도
        level: 3, // 지도의 확대 수준
      };

      // 지도 생성
      const map = new window.kakao.maps.Map(mapContainer, mapOption);

      // 마커 생성
      const markerPosition = new window.kakao.maps.LatLng(
        37.793954074365,
        127.235845842662
      ); // 마커의 위치
      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
      });

      // 마커를 지도에 표시
      marker.setMap(map);

      // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
      var mapTypeControl = new window.kakao.maps.MapTypeControl();

      // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
      // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
      map.addControl(
        mapTypeControl,
        window.kakao.maps.ControlPosition.TOPRIGHT
      );

      // 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
      var zoomControl = new window.kakao.maps.ZoomControl();
      map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);
    };

    loadKakaoMap(); // 카카오 지도 API 로드
  }, []);

  const handleMoveToProduct = (item: any) => {
    console.log("item : ", item);
    if (item.prd_id) {
      navigate(
        `/product-detail?prd_id=${item.prd_id}&type=single&categoryId=${categoryNameString}`,
        {
          replace: true,
        }
      );
    } else {
      navigate(
        `/product-detail?prd_id=${clickedSetId}&prd_name=${item.set_name}&type=set`,
        { replace: true }
      );
    }
  };

  const [processImg, setProcessImg] = useState<string>(workProcess);
  const [processAniIndex, setProcessAniIndex] = useState<number>(0);

  const workProcessList = [
    { imgUrl: image01, text: "압출", arrowIcon: true },
    { imgUrl: image02, text: "가공", arrowIcon: true },
    { imgUrl: image03, text: "포장", arrowIcon: true },
    { imgUrl: image04, text: "보관 및 출고", arrowIcon: false },
  ];

  useEffect(() => {
    const handleAnimation = setInterval(() => {
      setProcessAniIndex((prevIndex) => {
        if (prevIndex < 3) {
          return prevIndex + 1;
        } else {
          return 0;
        }
      });
    }, 5000);

    // 컴포넌트 언마운트 시 interval 정리
    return () => clearInterval(handleAnimation);
  }, []);

  useEffect(() => {
    setProcessImg(workProcessList[processAniIndex].imgUrl);

    if (workProcessListRef.current) {
      workProcessListRef.current.map((item, itemIndex) => {
        item.classList.remove("workprocess_active");
      });
      workProcessListRef.current[processAniIndex].classList.add(
        "workprocess_active"
      );
    }
  }, [processAniIndex]);

  const handleWorkProcessList = (e: any, index: number) => {
    if (workProcessListRef.current) {
      workProcessListRef.current.map((item, itemIndex) => {
        item.classList.remove("workprocess_active");
        if (itemIndex === index) {
          e.currentTarget.classList.add("workprocess_active");
          setProcessImg(workProcessList[index].imgUrl);
          setProcessAniIndex(index);
        }
      });
    }
  };

  // console.log("categoryName[0] : ", categoryName[0]);

  return (
    <PageWrap>
      <HeaderSection>
        <Header isWhiteLogo={true} categoryName={categoryUrlName} />
        <IntroText>
          <h3>크린에코와 함께 깨끗하게 요리하세요</h3>
          <p>당신의 식탁을 신선하게 지속시키도록 노력합니다</p>
        </IntroText>
        <SubNav></SubNav>
      </HeaderSection>
      <Section_01>
        <Section_01Wrap>
          <Slider {...slider_settings}>
            <SlideItem>
              <div>
                <img src={BussinessPolicy} alt="" />
                <div className="textcontent">
                  <p>성실하고 올바른 경영</p>
                  <p>
                    좋은 품질의 제품은 좋은 환경에서 생산됩니다.
                    <br />
                    좋은 직원의 출발지는 올바른 경영입니다.
                    <br />
                    언제나 노사 화합과 발전에 힘을 쓰고 있습니다.
                  </p>
                </div>
              </div>
            </SlideItem>
            <SlideItem>
              <div>
                <img src={QualityPolicy} alt="" />
                <div className="textcontent">
                  <p>고객 신뢰의 답, 정직한 제품</p>
                  <p>
                    여러번의 검증을 거쳐 생산하는 제품을 통해 <br />
                    고객 만족을 이루려 노력합니다.
                    <br />
                    고객 신뢰의 답은 정직한 제품입니다.
                  </p>
                </div>
              </div>
            </SlideItem>
            <SlideItem>
              <div>
                <img src={EcoPolicy} alt="" />
                <div className="textcontent">
                  <p>공익을 추구하는 제품</p>
                  <p>
                    저희가 생산한 제품은 끝까지 책임지려 하는 태도로 <br />
                    환경에 해를 끼치지 않도록 환경 법규를 준수합니다.
                  </p>
                </div>
              </div>
            </SlideItem>
            {/* <SlideItem>
              <div>
                <img src="" alt="" />
                <div className="textcontent">
                  <p>더미</p>
                  <p>
                    크린에코는 환경을 생각합니다. <br />
                    어떻게 생각하냐면
                    <br />
                    이렇게 저렇게 잘 생각합니다
                  </p>
                </div>
              </div>
            </SlideItem> */}
          </Slider>
        </Section_01Wrap>
      </Section_01>
      <Section_02>
        <ImgBox>
          <span className="green-element"></span>
          <p>
            <img src={processImg} />
          </p>
        </ImgBox>
        <TextBox>
          <div>
            <h4>크린에코의 작업과정</h4>
            <p>
              크린에코는 원단 생산에서 가공, 포장, 물류까지
              <br /> 모든 과정을 엄격히 관리합니다
            </p>
          </div>
          <Section_02_List>
            <ul>
              {workProcessList.map((item, index) => {
                return (
                  <>
                    <li
                      className="img_listitem"
                      ref={(el: HTMLLIElement) => {
                        workProcessListRef.current[index] = el;
                      }}
                      onClick={(e) => {
                        handleWorkProcessList(e, index);
                      }}
                    >
                      <p className="icon">
                        <img src={item.imgUrl} />
                      </p>
                      <p className="text">{item.text}</p>
                    </li>
                    {item.arrowIcon && (
                      <li>
                        <img src={ArrowIcon} />
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </Section_02_List>
        </TextBox>
      </Section_02>
      <Section_03>
        <TitleBox>
          <h4>
            <img src={PlantIcon} />
            크린에코의 대표 제품들
          </h4>
          <p>크린에코의 대표적인 상품들을 소개합니다</p>
        </TitleBox>
        <ContentBox>
          <ul>
            {categoryName.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={(e) => {
                    onShowProductList(e, item);
                  }}
                  ref={(el) => (liRefs.current[index] = el)}
                >
                  <img src={ImgList[index]} />
                  <p>
                    {item.category_name
                      ? item.category_name
                      : item.set_category_name}
                  </p>
                </li>
              );
            })}
          </ul>
          <div>
            <div>
              <ul>
                {productList && productList.length > 0 ? (
                  productList.slice(0, 3).map((item, index) => {
                    return (
                      <li
                        key={`${animKey}-${index}`}
                        onClick={() => {
                          handleMoveToProduct(item);
                        }}
                        ref={(el) => (productListRef.current[index] = el)}
                      >
                        {/* <img src={imgUrl[index]} /> */}
                        <img
                          src={`data:image/jpeg;base64,${
                            item.prd_thumbnail_img || item.set_prd_thumbnail_img
                          }`}
                        />
                        <p> {item.prd_name ? item.prd_name : item.set_name}</p>
                      </li>
                    );
                  })
                ) : (
                  <></>
                )}
              </ul>
              {/* <p>다양한 시도를 통한 위생장갑 생산에 심혈을 기울였습니다</p> */}
            </div>
            <button
              onClick={() => {
                navigate(
                  categoryName[0]?.category_id
                    ? `/product?categoryId=${categoryName[0]?.category_id}`
                    : `/product?setCategoryId=${
                        categoryName[0]?.set_category_id !== undefined
                          ? categoryName[0]?.set_category_id
                          : "0"
                      }`
                );
              }}
            >
              <span>더 많은 제품 보러가기</span> <img src={ArrowLong} />
            </button>
          </div>
        </ContentBox>
      </Section_03>
      <Section_04>
        <TitleBox>
          <h4>오시는 길</h4>
        </TitleBox>
        <Address>
          <RiMapPin2Fill size={18} color="#229CF0" />
          &nbsp; ㈜크린에코 l 경기도 포천시 내촌면 금강로 2480-39
          {/* <button>복사</button> */}
          {/* <br />
          <RiPhoneLine size={18} color="#229CF0" />
          &nbsp; tel : 010-000-0000 */}
        </Address>
        <div>
          <div id="map" style={{ width: "100%", height: "400px" }}></div>
        </div>
      </Section_04>
      <Footer>
        <FooterTop>
          <div>
            <p>
              <img src={logoWhite} />
            </p>
            <div>
              <p>
                <span>㈜크린에코 : </span> 경기도 포천시 내촌면 금강로 2480-39
              </p>
              <div>
                <p>
                  <span>대표 : </span>이현규
                </p>
                <p>
                  <span>TEL : </span> 031-532-6078
                </p>
              </div>
            </div>
            <div>
              <p>
                <span>E-mail : </span> 9782326@naver.com
              </p>
              <div>
                <p>
                  <span>FAX : </span> 031-532-1074
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </FooterTop>
        <FooterBottom>
          <div>
            <p>COPYRIGHT (C) 2024 ㈜크린에코 ALL RIGHTS RESERVED.</p>
          </div>
        </FooterBottom>
      </Footer>
    </PageWrap>
  );
}
