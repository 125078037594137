import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import logoWhite from "../../assets/img/cleaneco_logo-white.png";
import logoColor from "../../assets/img/cleaneco_logo-color.png";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const HeaderSection = styled.header`
  /* width: auto;
  padding: 10px 40px 0; */
  /* width: 80%; */
  width: 80%;
  margin: 0 auto;
  /* width: 100%; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.div`
  width: auto;
  height: 80px;
  img {
    height: 100%;
  }
`;
const Nav = styled.nav`
  width: fit-content;
  & > ul {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;

    & > li {
      cursor: pointer;
      position: relative;

      & + li {
        margin-left: 10px;
      }

      div {
        position: absolute;
        top: 30px;
        left: 0;
        width: fit-content;
        padding: 20px;
        box-sizing: border-box;
        border: 1px solid #cbcbcb;
        background: #fff;

        p {
          font-size: 15px;
          color: #cbcbcb;
          white-space: nowrap;
          & + p {
            margin-top: 5px;

            border-top: 1px solid #cbcbcb;
            padding-top: 5px;
          }

          a {
            color: #b9b9b9;
          }
        }
      }
      a {
        color: #444;
      }
    }
  }
`;

export function AdminHeader() {
  const navigate = useNavigate();

  const cookies = new Cookies();

  const [isNavListShow, setIsNavListShow] = useState<boolean>(false);
  const [isCategoryNavListShow, setIsCategoryNavListShow] =
    useState<boolean>(false);

  /**상단 네비 클릭 기능 동작 */
  const handleNavCompany = (e: any) => {
    if (!isNavListShow) {
      setIsNavListShow(true);
    } else {
      setIsNavListShow(false);
    }
  };

  /**상단 네비 클릭 기능 동작 */
  const handleCategoryNavCompany = (e: any) => {
    if (!isCategoryNavListShow) {
      setIsCategoryNavListShow(true);
    } else {
      setIsCategoryNavListShow(false);
    }
  };

  const handleLogout = () => {
    navigate("/admin-login");
    cookies.remove("user_info");
    toast.success("로그아웃 되었습니다");
  };

  return (
    <HeaderSection>
      <Logo>
        <Link to="/manage-product">
          <img src={logoColor} alt="logo-img" />
        </Link>
      </Logo>
      <Nav>
        <ul>
          <li onClick={handleNavCompany}>
            상품관리
            {isNavListShow && (
              <div>
                <p>
                  <Link to="/manage-product">단일</Link>
                </p>
                <p>
                  <Link to="/manage-set-product">세트</Link>
                </p>
              </div>
            )}
          </li>
          <li onClick={handleCategoryNavCompany}>
            카테고리 관리
            {isCategoryNavListShow && (
              <div>
                <p>
                  <Link to="/manage-category">단일 카테고리</Link>
                </p>
                <p>
                  <Link to="/manage-set-category">세트 카테고리</Link>
                </p>
              </div>
            )}
          </li>
          <li onClick={handleLogout}>로그아웃</li>
          {/* <li>
            <Link to="/manage_category">카테고리 관리</Link>
          </li> */}
        </ul>
      </Nav>
    </HeaderSection>
  );
}
