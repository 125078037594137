import { Pagination, Skeleton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { GetAllCategories } from "../../api/client/GetAllCategories";
import { Header } from "../../components/common/Header";
import Cookies from "universal-cookie";
import { useEffect, useRef, useState } from "react";
import { GetProducts } from "../../api/client/GetProducts";
import { GetProductThumbnailImg } from "../../api/client/GetProductThumbnailImg";
import { GetAllForLandingPage } from "../../api/client/GetAllForLandingPage";
import { GetSetProducts } from "../../api/client/GetSetProducts";
import { GetProductSetThumbnailImg } from "../../api/client/GetProductSetThumbnailImg";
import greenBgImg from "../../assets/img/cleaneco_green_bgImg.png";
import boxIcon from "../../assets/img/product/box_icon.png";
import queryString from "query-string";

const PageWrap = styled.div``;

const CategorySection = styled.div`
  /* background-color: #e6e6e6; */
  display: flex;
  justify-content: center;

  & > div {
    width: 80%;
    padding: 50px 0;
    box-sizing: border-box;
    text-align: center;

    ul {
      padding-top: 50px;
      display: flex;
      justify-content: center;

      li {
        float: left;
        color: #797979;
        cursor: pointer;
        font-weight: 100;
        padding-bottom: 8px;
        border-bottom: 4px solid;
        border-color: transparent;
        & + li {
          margin-left: 25px;
        }
      }

      li.active {
        border-color: rgb(91, 179, 49);
      }
    }

    @media (max-width: 1024px) {
      ul {
        display: block;
      }
    }
  }

  @media (max-width: 600px) {
    ul {
      margin-left: -11px;

      li {
        margin-left: 11px;
        margin-top: 11px;

        & + li {
          margin-left: 11px;
        }
      }
    }
  }
`;

const ProductBreadScrum = styled.div`
  width: 100%;
  height: 100px;
  line-height: 100px;
  background: url(${greenBgImg}) center no-repeat;
  background-size: cover;

  h5 {
    width: 80%;
    margin: 0 auto;
    color: #fff;
  }
`;

const ProductListSection = styled.div`
  width: 80%;
  margin: 40px auto 0;

  & > div {
    height: 40px;
    line-height: 40px;
    /* border-bottom: 3px solid #444;
    margin-bottom: 30px; */
  }

  ul {
    /* display: grid; */
    /* grid-template-columns: repeat(4, 24%); */
    /* grid-template-columns: repeat(6, 15.8%);
    grid-gap: 16px; */
    /* overflow: hidden;
    margin-left: -20px;
    margin-top: -50px; */

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    li {
      /* border: 1px solid #cbcbcb; */
      box-sizing: border-box;
      padding-bottom: 10px;
      /* width: 10%; */
      /* width:15%; */
      /* width: 230px;
      min-width: 150px;
      float: left;
      margin-left: 25px;
      margin-top: 50px; */

      a {
        display: block;
      }

      img {
        width: 100%;
      }

      .img-box {
        /* width: 150px; */
        /* width: 230px; */
        /* height: 150px; */
        /* height: 230px; */
        /* background-color: #cbcbcb; */
        /* border: 1px solid #e7e7e7; */
        border-radius: 2px;

        @media (max-width: 1024px) {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      div {
        width: 100%;
        /* line-height: 40px; */

        p {
          color: #7d7d7d;
          font-size: 14px;
          padding-top: 15px;
        }
        p:first-child {
          color: #444;
          font-size: 16px;

          + p {
            padding-top: 20px;
          }
        }
      }
    }
  }

  .noProduct {
    text-align: center;
    height: 400px;
    color: #888888;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      p {
        padding-top: 30px;
      }
    }
  }

  @media (max-width: 600px) {
    ul {
      li {
        /* width: 48%; */
        min-width: auto;
        margin-left: 0;
        /* margin-top: 20px; */
        &:nth-child(2n) {
          /* margin-left: 4%; */
        }
      }
    }
  }
`;

const PaginationWrap = styled.div`
  width: 80%;
  margin: 80px auto;
  display: flex;
  justify-content: center;
`;

export function Product() {
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsData = queryString.parse(window.location.search);
  const categoryId = queryParams.get("categoryId");
  const setCategoryId = queryParams.get("setCategoryId");
  const prdType =
    typeof queryParamsData.type === "string" ? queryParamsData.type : "";

  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [productSetList, setProductSetList] = useState<any[]>([]);
  const [updatedProductList, setUpdatedProductList] = useState<any[]>([]);
  const [clickedId, setClickedId] = useState<any[]>([]);
  const [imgUrl, setImgUrl] = useState<any[]>([]);

  //categoryList
  const categoryListRef = useRef<HTMLLIElement[] | []>([]);

  //pagination
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  //category click
  const [clickedCategoryName, setClickedCategoryName] = useState<string>("");

  // const [loading, setLoading] = useState(true);

  const params = {
    order_by: "category_id",
    order_type: "asc",
    set_order_by: "set_category_id",
    set_order_type: "asc",
  };

  const productParams = {
    page: page,
    rows: 12,
    order_by: "category_id",
    order_type: "asc",
  };

  const setProductParams = {
    page: page,
    rows: 12,
  };

  useEffect(() => {
    getAllGategories();
    // getAllForLandingPage();

    // /**
    //  * 뒤로가기
    //  */
    // const handlePopState = (event: any) => {
    //   console.log("브라우저의 뒤로가기 버튼이 클릭되었습니다!", event.state);
    //   // setHistoryCount((prevCount) => prevCount + 1); // 클릭할 때마다 카운트 증가
    // };

    // // popstate 이벤트 리스너 등록
    // window.addEventListener("popstate", handlePopState);

    // // 초기 상태 추가 (예: 페이지가 로드될 때)
    // window.history.pushState({ page: 1 }, "title 1", window.location.href);

    // return () => {
    //   // 컴포넌트 언마운트 시 리스너 제거
    //   window.removeEventListener("popstate", handlePopState);
    // };
  }, []);

  useEffect(() => {
    if (categoryId) {
      const categoryItem = categoryList.find(
        (item) => item.category_id == categoryId
      );

      if (categoryItem) {
        // handleCategoryClick(categoryItem, categoryList.indexOf(categoryItem));
      }
    } else if (setCategoryId) {
      const setCategoryItem = categoryList.find(
        (item) => item.set_category_id == setCategoryId
      );
      if (setCategoryItem) {
        // handleCategoryClick(
        //   setCategoryItem,
        //   categoryList.indexOf(setCategoryItem)
        // );
      }
    }
  }, [categoryId]);

  useEffect(() => {
    //쿼리 파라미터가 없는 경우
    if (categoryId === null && setCategoryId === null) {
      if (categoryListRef.current && categoryListRef.current[0]) {
        categoryListRef.current[0].style.fontWeight = "600";
        categoryListRef.current[0].style.color = "#444";
      }
    }

    /**
     * 페이지 로드 시, 쿼리 파라미터 감지하여 리스트 뿌리기
     */
    if (categoryId) {
      handleCategoryClick(categoryId);
    } else if (setCategoryId) {
      handleCategoryClick(setCategoryId);
    }
  }, [categoryList, page]);

  useEffect(() => {
    /**
     * 페이지 로드 시, 쿼리 파라미터 감지하여 리스트 뿌리기
     */
    if (categoryId) {
      getProducts(categoryId);
    } else if (setCategoryId) {
      getSetProducts(setCategoryId);
    }
  }, [page]);

  useEffect(() => {
    /**
     * 페이지 로드 시, 쿼리 파라미터 감지하여 리스트 뿌리기
     */
    if (categoryId) {
      // getProducts(categoryId);
      // getProductThumbnailImg(categoryId);
      // handleCategoryClick(categoryId);
    } else if (setCategoryId) {
      // console.log("setCategoryId : ", setCategoryId);
      // getSetProducts(setCategoryId);
      // getSetProductThumbnailImg(setCategoryId);
      // handleCategoryClick(setCategoryId);
    }
  }, [productList]);

  useEffect(() => {
    // const clickedIdArray = clickedId.sort((a, b) => a - b);

    // console.log("productList : ", productList);
    // console.log("imgUrl : ", imgUrl);

    // productList.map((item, index) => {
    //   if (item.prd_id === clickedIdArray[index]) {
    //     item["img"] = imgUrl[index];
    //   }
    // });

    // productSetList.map((item, index) => {
    //   if (item.prd_id === clickedIdArray[index]) {
    //     item["img"] = imgUrl[index];
    //   }
    // });

    // if (imgUrl) {
    if (productList.length > 0) {
      setUpdatedProductList(productList);
    } else {
      setUpdatedProductList(productSetList);
    }
    // }
  }, [productList, productSetList]); //imgUrl,

  // useEffect(() => {
  //   // 데이터가 로딩 중일 때 로딩 상태를 true로 설정
  //   if (updatedProductList.length > 0 || imgUrl.length > 0) {
  //     setLoading(false); // 데이터 로딩 완료
  //   }
  // }, [updatedProductList, imgUrl]);

  const getAllGategories = () => {
    GetAllCategories(current_cookies, params).then((response) => {
      const prdList = response.data.data.categories_info.categories;
      const setPrdList = response.data.data.set_categories_info.set_categories;

      setCategoryList([...prdList, ...setPrdList]);
    });
  };

  const getAllForLandingPage = () => {
    GetAllForLandingPage(current_cookies).then((response) => {
      if (response.data.status_info.status_code) {
        const prdList = response.data.data.single_category_prd_list;
        const setPrdList = response.data.data.set_category_set_name_list;

        // setCategoryList([...prdList, ...setPrdList]);
        // setCategoryName([...prdList, ...setPrdList]);
      }
    });
  };

  const getProducts = (id: string) => {
    GetProducts(current_cookies, id, productParams).then((response) => {
      if (response.data.status_info.status_code === 200) {
        setProductList(response.data.data.products_info);
        // setPage(response.data.data.page_info.current_page);
        setTotalPage(response.data.data.page_info.total_page);
        setProductSetList([]);
        // setLoading(false);

        // console.log("response.data.data. : ", response.data.data);
      }
    });
  };

  const getSetProducts = (id: string) => {
    GetSetProducts(current_cookies, id, setProductParams).then((response) => {
      if (response.data.status_info.status_code === 200) {
        // console.log("response : ", response.data.data.set_prd_grouped_info);
        // setPage(response.data.data.page_info.current_page);
        setProductSetList(response.data.data.set_prd_grouped_info);
        setTotalPage(response.data.data.page_info.total_page);
        setProductList([]);
        // setLoading(false);
      } else {
      }
    });
  };

  const [result, setResult] = useState<any[]>([]);

  // useEffect(() => {
  //   console.log("productList : ", productList);
  // }, [result, productList, page]);

  const getProductThumbnailImg = async (id: string) => {
    // const result = categoryList.filter((item) => {
    //   return item.category_id == id;
    // });

    const result = productList.filter((item) => {
      return item.category_id == id;
    });

    setResult(result);

    const prdIdList: any[] = [];
    const imgUrlList: any[] = [];

    if (result) {
      const promises = result
        .sort((a: any, b: any) => a.prd_id - b.prd_id)
        .map(async (item: any, index: number) => {
          prdIdList.push(item.prd_id);
          setClickedId(prdIdList);

          const response = await GetProductThumbnailImg(
            current_cookies,
            item.prd_id
          );

          const imageBlob = new Blob([response.data], {
            type: response.data.type,
          });

          const imageUrl = URL.createObjectURL(imageBlob);

          imgUrlList[index] = imageUrl;
        });

      // 모든 비동기 작업이 완료될 때까지 기다림
      await Promise.all(promises);

      setImgUrl(imgUrlList);
    }
  };

  const getSetProductThumbnailImg = async (id: string) => {
    // const result = categoryList.filter((item) => {
    //   return item.set_category_id == id;
    // });

    const result = productSetList.filter((item) => {
      return item.set_category_id == id;
    });

    const prdIdList: any[] = [];
    const imgUrlList: any[] = [];

    if (result[0]) {
      const promises = result
        .sort((a: any, b: any) => a.prd_id - b.prd_id)
        .map(async (item: any, index: number) => {
          prdIdList.push(item.prd_id);
          setClickedId(prdIdList);

          const response = await GetProductSetThumbnailImg(
            current_cookies,
            id,
            item.set_name
          );

          const imageBlob = new Blob([response.data], {
            type: response.data.type,
          });

          const imageUrl = URL.createObjectURL(imageBlob);

          imgUrlList[index] = imageUrl;
        });

      // 모든 비동기 작업이 완료될 때까지 기다림
      await Promise.all(promises);

      setImgUrl(imgUrlList);
    }
  };

  const handleCategoryClick = (item: any, index?: number) => {
    if (typeof item == "object") {
      if (categoryListRef.current) {
        for (let i = 0; i < categoryListRef.current.length; i++) {
          categoryListRef.current[i].style.fontWeight = "300";
          categoryListRef.current[i].style.color = "#797979";
          categoryListRef.current[i].classList.remove("active");
          if (i === index) {
            categoryListRef.current[i].style.fontWeight = "600";
            categoryListRef.current[i].style.color = "#5BB331";
            categoryListRef.current[i].classList.add("active");
          }
        }
      }
    } else {
      //item에 해당하는 카테고리 리스트 index 추출
      const indexs = categoryList.findIndex((categoryItem) => {
        if (categoryList.length > 0 && prdType === "set") {
          return categoryItem.set_category_id == item;
        } else {
          return categoryItem.category_id == item;
        }
      });

      console.log("prdType : ", prdType);
      console.log("indexs : ", indexs);
      console.log("categoryList : ", categoryList);

      const filteredCategoryList = categoryList.filter((categoryItem) => {
        if (categoryItem.set_category_id == item) {
          return categoryItem.set_category_id == item;
        } else {
          return categoryItem.category_id == item;
        }
      });

      if (
        filteredCategoryList[0] &&
        filteredCategoryList[0].set_category_name
      ) {
        setClickedCategoryName(filteredCategoryList[0].set_category_name);
      } else if (
        filteredCategoryList[0] &&
        filteredCategoryList[0].category_name
      ) {
        setClickedCategoryName(filteredCategoryList[0].category_name);
      }

      if (categoryListRef.current) {
        for (let i = 0; i < categoryListRef.current.length; i++) {
          categoryListRef.current[i].style.fontWeight = "300";
          categoryListRef.current[i].style.color = "#797979";
          categoryListRef.current[i].classList.remove("active");
          if (i === indexs) {
            categoryListRef.current[i].style.fontWeight = "600";
            categoryListRef.current[i].style.color = "#5BB331";
            categoryListRef.current[i].classList.add("active");
          }
        }
      }
    }

    if (item.category_id !== undefined) {
      getProducts(item.category_id);
      // getProductThumbnailImg(item.category_id);
      setClickedCategoryName(item.category_name);
      // navigate(`/product&id=${item.category_id}`, { replace: true });
      navigate(`/product?categoryId=${item.category_id}`, { replace: true }); // URL 업데이트
      // window.history.pushState(
      //   {},
      //   "",
      //   `/product?categoryId=${item.category_id}`
      // );
    } else if (item.set_category_id !== undefined) {
      setClickedCategoryName(item.set_category_name);
      getSetProducts(item.set_category_id);
      // getSetProductThumbnailImg(item.set_category_id);
      navigate(`/product?setCategoryId=${item.set_category_id}`, {
        replace: true,
      }); // URL 업데이트
      window.history.pushState(
        {},
        "",
        `/product?setCategoryId=${item.set_category_id}`
      );
    }
  };

  // 페이지 변경 핸들러
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <PageWrap>
      <Header isWhiteLogo={false} categoryName={categoryList} />
      <ProductBreadScrum>
        <h5>
          상품소개 &gt; {""}
          {clickedCategoryName ||
            (categoryList[0] && categoryList[0].category_name)}
        </h5>
      </ProductBreadScrum>
      <CategorySection>
        <div>
          <h4>상품 카테고리</h4>
          <ul>
            {categoryList.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    handleCategoryClick(item, index);
                    setPage(1);
                    setUpdatedProductList([]);
                    setImgUrl([]);
                    // setLoading(true); // 데이터 로딩 완료
                  }}
                  ref={(e: HTMLLIElement) => {
                    categoryListRef.current[index] = e;
                  }}
                >
                  {item.category_name
                    ? item.category_name
                    : item.set_category_name}
                </li>
              );
            })}
          </ul>
        </div>
      </CategorySection>
      <ProductListSection>
        <ul>
          {productList.length > 0 ||
          productSetList.length > 0 ||
          updatedProductList.length > 0 ? (
            updatedProductList.map((item) => {
              return (
                <li
                  onClick={() => {
                    if (item.prd_id) {
                      navigate(
                        `/product-detail?prd_id=${item.prd_id}&type=single&categoryId=${item.category_id}`,
                        { replace: true }
                      );
                    } else {
                      navigate(
                        `/product-detail?prd_id=${item.set_category_id}&prd_name=${item.set_name}&type=set`,
                        { replace: true }
                      );
                    }
                  }}
                >
                  <Link to="/product-detail">
                    <p className="img-box">
                      <img
                        src={`data:image/jpeg;base64,${
                          item.prd_thumbnail_img || item.set_thumbnail_img
                        } `}
                      />
                    </p>
                    <div>
                      <p>
                        <b style={{ color: "#444" }}>
                          {item.prd_name || item.set_name}
                        </b>
                      </p>
                      {item.prd_standard ? (
                        <p>
                          <b style={{ color: "#444", paddingRight: "10px" }}>
                            규격
                          </b>
                          {item.prd_standard}
                        </p>
                      ) : (
                        <></>
                      )}

                      {item.prd_quantity && (
                        <p>
                          <b style={{ color: "#444", paddingRight: "10px" }}>
                            매수
                          </b>
                          {item.prd_quantity}
                        </p>
                      )}
                    </div>
                  </Link>
                </li>
              );
            })
          ) : (
            <div className="noProduct">
              <div>
                <img src={boxIcon} />
                <p> 상품이 존재하지 않습니다</p>
              </div>
            </div>
          )}
        </ul>
      </ProductListSection>
      <PaginationWrap>
        <Pagination
          count={totalPage || 1}
          shape="rounded"
          page={page}
          onChange={handleChangePage}
        />
      </PaginationWrap>
    </PageWrap>
  );
}
