import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function GetCategory(token: string) {
  return axios.get(`${apiUrl}/api/admin/category`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
