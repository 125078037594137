import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  page: number;
  rows: number;
}

export function GetSetProducts(
  token: string,
  category_id: string,
  params: Props
) {
  return axios.get(`${apiUrl}/api/client/set-products/${category_id}`, {
    params: {
      page: params.page,
      rows: params.rows,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
