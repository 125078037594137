import { Dispatch, SetStateAction } from "react";
import { styled } from "styled-components";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ModalWrap = styled.div`
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 50%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  /* width: 200px; */
  width: 500px;
  height: 180px;
  transform: translate(-250px, -100px);

  h3 {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-around;

    button {
      width: 40%;
      background-color: #ededed;
      border: none;
      padding: 5px;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: #d3d3d3;
      }
    }
  }
`;

interface Props {
  setIsCheckModal: Dispatch<SetStateAction<boolean>>;
  setHistoryCount: Dispatch<SetStateAction<number>>;
  historyCount: number;
}

export function CheckModal({
  setIsCheckModal,
  setHistoryCount,
  historyCount,
}: Props) {
  return (
    <Wrap>
      <ModalWrap>
        <h3>수정 중인 내용이 저장되지 않습니다. 뒤로 가시겠습니까?</h3>
        <div>
          <button
            onClick={() => {
              setIsCheckModal(false);
              setHistoryCount(historyCount + 1);
            }}
          >
            확인
          </button>
          <button
            onClick={() => {
              setIsCheckModal(false);
              setHistoryCount(historyCount - 1);
            }}
          >
            취소
          </button>
        </div>
      </ModalWrap>
    </Wrap>
  );
}
