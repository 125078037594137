import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import logoWhite from "../../assets/img/cleaneco_logo-white.png";
import logoColor from "../../assets/img/cleaneco_logo-color.png";

const HeaderSection = styled.header`
  width: auto;
  padding: 10px 40px 0;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.div`
  width: auto;
  height: 80px;
  p {
    height: 100%;
  }
  img {
    height: 100%;
  }
`;
const Nav = styled.nav<{ isWhiteLogo: boolean }>`
  & > ul {
    width: 150px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => (props.isWhiteLogo ? "#fff" : "#000")};

    & > li {
      cursor: pointer;
      position: relative;

      div {
        position: absolute;
        top: 30px;
        left: 0;
        width: 170px;
        left: -60px;
        text-align: center;
        background: ${(props) =>
          props.isWhiteLogo ? "rgba(0, 0, 0, 0.3)" : "#fff"};

        box-sizing: border-box;
        border: ${(props) =>
          props.isWhiteLogo ? "none" : "1px solid #cbcbcb"};
        overflow: hidden;
        transition: all 0.3s;

        &.show {
          height: 130px;
          padding: 20px;
          border-width: 1px;
        }

        &.hide {
          height: 0;
          padding: 0px;
          border: none;
        }

        p {
          font-size: 15px;
          color: #cbcbcb;
          & + p {
            margin-top: 23px;
            position: relative;

            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 50%;
              height: 1px;
              left: 25%;
              top: -10px;
              background-color: ${(props) =>
                props.isWhiteLogo ? "#737373" : "#d0d0d0"};
            }
          }

          a {
            color: ${(props) => (props.isWhiteLogo ? "#b9b9b9" : "#000")};
          }
        }
      }
      a {
        color: ${(props) => (props.isWhiteLogo ? "#fff" : "#000")};
      }
    }
  }
`;

export function Header({
  isWhiteLogo,
  disableMove,
  categoryName,
}: {
  isWhiteLogo: boolean;
  disableMove?: boolean;
  categoryName?: any[];
}) {
  const navigate = useNavigate();
  const [isNavListShow, setIsNavListShow] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);

  /**상단 네비 클릭 기능 동작 */
  const handleNavCompany = (e: any) => {
    if (!disableMove) {
      if (!isNavListShow) {
        setIsNavListShow(true);
        if (divRef.current) {
          divRef.current.classList.add("show");
          divRef.current.classList.remove("hide");
        }
      } else {
        setIsNavListShow(false);
        if (divRef.current) {
          divRef.current.classList.remove("show");
          divRef.current.classList.add("hide");
        }
      }
    }
  };

  return (
    <HeaderSection>
      <Logo>
        {disableMove ? (
          <p>
            {isWhiteLogo ? (
              <img src={logoWhite} alt="logo-img" />
            ) : (
              <img src={logoColor} alt="logo-img" />
            )}
          </p>
        ) : (
          <Link to="/">
            {isWhiteLogo ? (
              <img src={logoWhite} alt="logo-img" />
            ) : (
              <img src={logoColor} alt="logo-img" />
            )}
          </Link>
        )}
      </Logo>
      <Nav isWhiteLogo={isWhiteLogo}>
        <ul>
          <li onClick={handleNavCompany}>
            회사소개
            {/* {isNavListShow && ( */}
            <div ref={divRef} className={isNavListShow ? "show" : "hide"}>
              {/* <p><Link to="/company-history">회사연혁</Link></p> */}

              <p>
                {disableMove ? (
                  <Link to="">CEO 인사말</Link>
                ) : (
                  <Link to="/ceo">CEO 인사말</Link>
                )}
              </p>
              <p>
                {disableMove ? (
                  <Link to="">경영 방침</Link>
                ) : (
                  <Link to="/management_policy">경영 방침</Link>
                )}
              </p>
              <p>
                {disableMove ? (
                  <Link to="">생산 공정</Link>
                ) : (
                  <Link to="/production_process">생산 공정</Link>
                )}
              </p>
            </div>
            {/* )} */}
          </li>
          <li>
            {disableMove ? (
              <p>상품소개</p>
            ) : (
              // <Link to="/product">
              <a
                // to="/product"
                onClick={() => {
                  navigate(
                    `/product?categoryId=${
                      categoryName && categoryName[0]?.category_id
                    }`
                  );
                }}
              >
                상품소개
              </a>
              // </Link>
            )}
          </li>
        </ul>
      </Nav>
    </HeaderSection>
  );
}
