import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function DeleteSetCategory(token: string, set_category_id: number) {
  return axios.delete(`${apiUrl}/api/admin/set-category/${set_category_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
