import { Pagination, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import { styled } from "styled-components";
import { PostCategory } from "../../api/category/single/PostCategory";
import { AdminHeader } from "../../components/common/AdminHeader";
import { Header } from "../../components/common/Header";
import Cookies from "universal-cookie";
import { GetCategoryWithPrdQuantity } from "../../api/category/single/GetCategoryWithPrdQuantity";
import { DeleteCategory } from "../../api/category/single/DeleteCategory";
import { PostSetCategory } from "../../api/category/set/PostSetCategory";
import { GetSetCategory } from "../../api/category/set/GetSetCategory";
import { PutSetCategory } from "../../api/category/set/PutSetCategory";
import { DeleteSetCategory } from "../../api/category/set/DeleteSetCategory";
import { DeleteModal } from "../../components/modal/DeleteModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PageWrap = styled.div``;

const ContentWrap = styled.div`
  width: 80%;
  margin: 0 auto;
`;
const Title = styled.div`
  width: 100%;
  padding: 20px 0;
`;
const CategoryContent = styled.div`
  background-color: #f5f5f5;
  border-top: 2px solid #444;
  padding: 30px 30px 80px;
  box-sizing: border-box;

  display: flex;
  justify-content: flex-start;
`;

const CategorySection = styled.div``;

const CategoryBox = styled.div`
  box-sizing: border-box;
  width: 400px;
  height: fit-content;
  background-color: #fff;
  & > ul {
    padding: 20px;
    box-sizing: border-box;
    height: 250px;
    li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cbcbcb;
      font-size: 15px;
      & > p {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.count {
          width: 100px;
        }
      }

      div {
        button {
          /* border: 1px solid #cbcbcb; */
          border: none;
          background-color: #fff;
          font-size: 14px;
          padding: 5px;
          box-sizing: border-box;
          cursor: pointer;
          transition: all 0.3s;
          color: #818181;

          /* &:last-child {
            border: none;
            color: #818181;
          } */

          & + button {
            margin-left: 5px;
          }

          &:hover {
            color: #a8a8a8;
          }
          /* 
          &:last-child:hover {
            background-color: #fff;
            color: #a8a8a8;
          } */
        }
      }
    }

    & > p {
      width: 100%;
      height: 100%;
      line-height: 250px;
      text-align: center;
      color: #787878;
    }
  }
`;

const PaginationSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AddCategory = styled.div`
  margin-top: 30px;
  background-color: #dcdcdc;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border: 1px solid #5bb331;
    color: #5bb331;
    background-color: #fff;
    height: 30px;
    padding: 0 5px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #e7e7e7;
    }
  }
`;

const NewCategory = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  button {
    width: 50px;
    height: 40px;
    border: 1px solid #aaa;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #e7e7e7;
    }

    & + button {
      margin-left: 5px;
      background-color: #5bb331;
      color: #fff;
      border: none;
      &:hover {
        background-color: #4f9c2b;
      }
    }
  }
`;

const CategoryGuide = styled.div`
  width: 400px;
  margin-left: 50px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #e5e5e5;

  h5 {
    font-size: 16px;
    padding-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #444;
    font-weight: 300;
  }
`;

export function ManageSetCategory() {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const navigate = useNavigate();

  const [addCategory, setAddCategory] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [newCategory, setNewCategory] = useState<
    {
      set_category_id: number;
      set_category_name: string;
      set_prd_count: number;
    }[]
  >([]);
  const [isEdit, setIsEdit] = useState<boolean>(false); //편집 플래그
  const [selectedItem, setSelectedItem] = useState<
    { set_category_id: number; set_category_name: string } | any
  >({}); //편집 플래그
  const [newCategoryValue, setNewCategoryValue] = useState<
    { id: number; name: string } | any
  >({});

  // Pagination states and handlers
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 5;

  //카테고리 리스트
  const [categoryPage, setCategoryPage] = useState<{
    total_page: number;
    current_page: number;
    total_count: number;
  }>({
    total_page: 2,
    current_page: 1,
    total_count: 6,
  });

  // const currentItems = newCategory.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const { handleSubmit, control, reset } = useForm();
  const {
    field: category_name,
    fieldState: { invalid: category_name_Field },
  } = useController({
    name: "category_name",
    control,
    defaultValue: "",
  });

  const {
    field: category_name_edit,
    fieldState: { invalid: category_name_edit_Field },
  } = useController({
    name: "category_name_edit",
    control,
    defaultValue: "",
  });

  // useEffect(() => {
  //   getCategoryWithPrdQuantity();
  // }, []);

  useEffect(() => {
    if (newCategory.length > itemsPerPage * currentPage) {
      // setCurrentPage((prev) => prev + 1);
    }

    getSetCategory();
  }, [currentPage]);

  const params = {
    page: currentPage,
    rows: 5,
    order_by: "category_id",
    order_type: "asc",
  };

  /**카테고리 리스트 GET api*/
  const getSetCategory = () => {
    GetSetCategory(current_cookies, params)
      .then((response) => {
        setNewCategory(response.data.data.set_categories_info);
        setCategoryPage(response.data.data.page_info);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  /**카테고리 POST api */
  const postSetCategory = (category_name: string) => {
    PostSetCategory(current_cookies, category_name).then((response) => {
      if (response.data.status_info.status_code === 201) {
        getSetCategory();
        toast.success("카테고리가 등록되었습니다");
      }
    });
  };

  const updateParams = {
    set_category_id: newCategoryValue.id,
    update_set_category_name: newCategoryValue.name,
  };

  /**카테고리 PUT api */
  const putSetCategory = () => {
    PutSetCategory(current_cookies, newCategoryValue.id, updateParams).then(
      (response) => {
        if (response.data.status_info.status_code === 200) {
          toast.success("카테고리가 수정되었습니다");
        }
      }
    );
  };

  /**카테고리 DELETE api */
  const deleteCategory = (category_id: number) => {
    DeleteSetCategory(current_cookies, category_id)
      .then((response) => {
        console.log("response : ", response);

        if (response.status === 204) {
          toast.success("삭제되었습니다");
          getSetCategory();
          setIsDelete(false);
          setDeleteProduct(false);
        } else if (response.status === 400) {
          toast.error(response.data.status_info.message);
          setIsDelete(false);
          setDeleteProduct(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error("해당 카테고리에 상품이 존재하여 삭제할 수 없습니다");
          // setIsDelete(false);
          // setDeleteProduct(false);
        }
      });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const onHandleAddCategory = () => {
    setAddCategory(true);
  };

  const onSubmit = (DATA: any) => {
    postSetCategory(DATA.category_name);

    setAddCategory(false);
    reset({ category_name: "" });
  };

  const onHandleEditCategory = (item: {
    set_category_id: number;
    set_category_name: string;
  }) => {
    setSelectedItem(item);
    setIsEdit(true);
  };

  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  useEffect(() => {
    if (deleteProduct === true) {
      deleteCategory(idToDelete);
    }
  }, [deleteProduct]);

  const onHandleDeleteCategory = (id: number) => {
    setIsDelete(true);
    setIdToDelete(id);
  };

  const onChangedInputValue = (e: any, id: number) => {
    setNewCategoryValue({ id: id, name: e.target.value });
  };

  const onHandleApplyNewValue = () => {
    setNewCategory((prev) => {
      const prevState = [...prev];

      prevState.map((item) => {
        if (item.set_category_id === newCategoryValue.id) {
          // item.id = newCategoryValue.id;
          item.set_category_name = newCategoryValue.name;
        }
      });

      return prevState;
    });

    putSetCategory();

    setIsEdit(false);
  };

  return (
    <>
      <PageWrap>
        <AdminHeader />
        <ContentWrap>
          <Title>
            <p>세트 카테고리 관리</p>
          </Title>
          <CategoryContent>
            <CategorySection>
              <CategoryBox>
                <ul>
                  {newCategory && newCategory.length > 0 ? (
                    newCategory.map((item, index) => {
                      return (
                        <li key={index}>
                          <p>
                            {isEdit &&
                            item.set_category_id ===
                              selectedItem.set_category_id ? (
                              <input
                                type="text"
                                defaultValue={item.set_category_name}
                                onChange={(event) => {
                                  onChangedInputValue(
                                    event,
                                    item.set_category_id
                                  );
                                }}
                              />
                            ) : (
                              item.set_category_name
                            )}
                          </p>
                          <p className="count">상품개수 {item.set_prd_count}</p>
                          <div>
                            {isEdit &&
                            item.set_category_id ===
                              selectedItem.set_category_id ? (
                              <button onClick={onHandleApplyNewValue}>
                                확인
                              </button>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    onHandleEditCategory(item);
                                  }}
                                >
                                  수정
                                </button>
                                <button
                                  onClick={() => {
                                    onHandleDeleteCategory(
                                      item.set_category_id
                                    );
                                  }}
                                >
                                  삭제
                                </button>
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <p>새 카테고리를 추가해주세요</p>
                  )}
                </ul>
                <PaginationSection>
                  <Pagination
                    count={
                      newCategory && newCategory.length > 0
                        ? Math.ceil(categoryPage.total_count / itemsPerPage)
                        : 1
                    }
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </PaginationSection>
                <AddCategory>
                  <button onClick={onHandleAddCategory}>+ 카테고리 추가</button>
                  <p>총 {categoryPage && categoryPage.total_count} 개</p>
                </AddCategory>
              </CategoryBox>
              {addCategory && (
                <NewCategory onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    id="filled-basic"
                    size="small"
                    // fullWidth
                    label="새 카테고리 이름"
                    placeholder="새 카테고리 이름을 입력해주세요"
                    variant="filled"
                    {...category_name}
                    error={category_name_Field}
                    required
                    style={{ width: "250px" }}
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setAddCategory(false);
                      }}
                    >
                      취소
                    </button>
                    <button type="submit">확인</button>
                  </div>
                </NewCategory>
              )}
            </CategorySection>
            <CategoryGuide>
              <h5>카테고리 관리 안내</h5>
              <br />
              <p>
                1) 카테고리 추가는 한 항목씩 가능합니다.
                <br /> <br />
                2) 카테고리 수정은 카테고리 이름을 변경합니다.
                <br /> <br />
                3) 카테고리 삭제는 상품이 포함된 경우 불가합니다. <br /> <br />
                (카테고리에 포함된 모든 상품을 제거 후 삭제 가능합니다.)
              </p>
            </CategoryGuide>
          </CategoryContent>
        </ContentWrap>
      </PageWrap>
      {isDelete && (
        <DeleteModal
          setIsDelete={setIsDelete}
          setDeleteProduct={setDeleteProduct}
        />
      )}
    </>
  );
}
