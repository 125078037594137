import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function DeleteCategory(token: string, category_id: number) {
  return axios.delete(`${apiUrl}/api/admin/category/${category_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
