import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function PostCategory(token: string, category_name: string) {
  return axios.post(`${apiUrl}/api/admin/category`, null, {
    params: {
      category_name: category_name,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
