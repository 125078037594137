import { styled } from "styled-components";
import { Header } from "./common/Header";
import { GetProductThumbnailImg } from "../api/client/GetProductThumbnailImg";
import queryString from "query-string";
import Cookies from "universal-cookie";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

const PageWrap = styled.div`
  position: relative;
  /* border: 3px solid #cbcbcb; */
  & > button {
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    text-align: center;
    position: sticky;
    /* left: 50%; */
    top: 0;
    /* transform: translateX(-150px); */
    cursor: pointer;
    font-size: 16px;
    color: #444;
  }
`;

const ContentWrap = styled.div`
  width: 60%;
  margin: 0 auto;
`;
const Title = styled.div`
  font-size: 14px;
  padding-bottom: 30px;
`;
const ThumbNail = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const ThumbNailImg = styled.div`
  width: 500px;
  height: 500px;
  /* background-color: #cbcbcb; */

  img {
    width: 100%;
  }
`;

const ThumbNailDetail = styled.div`
  padding-left: 100px;
  width: 400px;

  h3 {
    line-height: 56px;
    border-bottom: 3px solid #5bb331;
    font-size: 16px;
  }

  & > div {
    display: flex;
    padding: 30px 0;
    border-bottom: 1px solid #cbcbcb;
    /* & + div {
      padding-top: 50px;
    } */

    p {
      font-size: 16px;
      white-space: nowrap;

      &:first-child {
        width: 100px;
      }

      & + p {
        padding-left: 30px;
        color: #777;
        font-weight: 100;
      }

      div {
        & + div {
          padding-top: 10px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const ContentDetail = styled.div`
  padding-top: 50px;

  h4 {
    padding-bottom: 20px;
  }
  div {
    /* background-color: #cbcbcb; */
    height: 1000px;
    img {
      width: 100%;
    }
  }
`;

interface Props {
  data: any;
  thumbnail: any;
  setIsShowPreview: Dispatch<SetStateAction<boolean>>;
  detailImg: any[];
  categoryInfo?: {
    category_id: number;
    category_name: string;
  };
  categorySetInfo?: {
    set_category_id: number;
    set_category_name: string;
    set_prd_count: number;
  };
  prdName?: string;
  setPrdData?: any[];
}

export function ProductDetailPreview({
  data,
  thumbnail,
  setIsShowPreview,
  detailImg,
  categoryInfo,
  categorySetInfo,
  prdName,
  setPrdData,
}: Props) {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");
  const queryParams = queryString.parse(window.location.search);

  console.log("data : ", data);

  return (
    <PageWrap>
      <button
        onClick={() => {
          setIsShowPreview(false);
        }}
      >
        미리보기 종료
      </button>
      <Header isWhiteLogo={false} disableMove />
      <ContentWrap>
        <Title>
          <p>
            상품소개 &gt;{" "}
            {data.single_product ||
              categoryInfo?.category_name ||
              categorySetInfo?.set_category_name}{" "}
            &gt; {data.product_name || data.set_product_name || prdName}
          </p>
        </Title>
        <ThumbNail>
          <ThumbNailImg>
            <img src={thumbnail} />
          </ThumbNailImg>
          <ThumbNailDetail>
            <h3>상품 정보</h3>
            <div>
              <p>상품이름</p>
              <p>{data.set_product_name || data.product_name || ""}</p>
            </div>
            <div>
              <p>박스 입수</p>
              <p>{data.set_box_count || data.box_count || ""}</p>
            </div>
            {"set_product_name" in data ? (
              <div>
                <p>구성품</p>
                <p>
                  <div>
                    {data.product_name} |{data.product_count} 매
                  </div>
                  {setPrdData?.map((item) => {
                    return (
                      <div>
                        {data[`product_name_${item.set_prd_id}`]} |
                        {data[`product_count_${item.set_prd_id}`]} 매
                      </div>
                    );
                  })}
                </p>
              </div>
            ) : (
              <>
                <div>
                  <p>규격</p>
                  <p>{data.product_size || ""}</p>
                </div>
                <div>
                  <p>매수</p>
                  <p>{data.product_count || ""}</p>
                </div>
              </>
            )}
          </ThumbNailDetail>
        </ThumbNail>
        <ContentDetail>
          <h4>상품소개</h4>

          <div>
            {detailImg.map((item: any) => {
              return <img src={item ? item.toString() : ""} />;
            })}
          </div>
        </ContentDetail>
      </ContentWrap>
    </PageWrap>
  );
}
