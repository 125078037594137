import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  set_category_id: number;
  update_set_category_name: string;
}

export function PutSetCategory(
  token: string,
  set_category_id: string,
  updateParams: Props
) {
  return axios.put(
    `${apiUrl}/api/admin/set-category/${set_category_id}`,
    null,
    {
      params: {
        set_category_id: updateParams.set_category_id,
        update_set_category_name: updateParams.update_set_category_name,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
}
